import React, { useReducer, useState, useEffect } from "react";
import { StyledModalcomponent } from "../../../Styled.jsx";
import Input from "../../../../Input/Input.jsx";
import CustomButton from "../../../../Button/CustomButton.tsx";
import Preloader from "../../../../Preloader/Preloader.jsx";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  getTipoUnidadesOptions,
  createUnidad,
  updateUnidad,
} from "../../../../../api/Configuracion";
const Unidad = (props) => {
  const propsUnidades = (datosUnidades) => {
    const ver = datosUnidades?.button === "Ver" ?? false;
    const editar = datosUnidades?.button === "Editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos };
  };
  const { ver, editar, datos } = propsUnidades(props.datos);

  const [formCompleto, setFormCompleto] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tipoUnidades, setTipoUnidades] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
 

  const [form, setForm] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_FIELD":
          return { ...state, [action.field]: action.value };
        default:
          return state;
      }
    },
    {
      id: datos.id ?? "",
      codigo: datos.codigo ?? "",
      descripcion: datos.descripcion ?? "",
      tipoUnidad: datos.tipoId ?? "",
      simbolo: datos.simbolo ?? "",
      habilitado: datos.habilitado === "ACTIVE" || datos.habilitado === "SI" ? true : false,
    }
  );

  useEffect(() => {
    const fetchTipoUnidades = async () => {
      const res = await getTipoUnidadesOptions();
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_tpuni,
      }));
      setTipoUnidades(unidades);
      if (datos && (ver || editar)) {
        const matchOption = unidades.find(
          (option) => option.value === datos.tipoId
        );
        if (matchOption) {
          setSelectedOption(matchOption);
        } else{
          setSelectedOption(unidades[0]);
        }
      };
    }
      fetchTipoUnidades();
    }, []);

  useEffect(() => {
    const campoValido = (campo) =>
      form[campo] !== "" && (campo !== "habilitado" || form[campo]);
    const camposaValidar = ["codigo", "descripcion", "simbolo", "habilitado"];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === "checkbox" ? checked : value;
      setForm({ type: "SET_FIELD", field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
      setSelectedOption(e);
      setForm({ type: "SET_FIELD", field: name, value: value });
    }
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    let formatterForm = {
      id: form.id,
      codigo_uni: form.codigo.toString(),
      desc_uni: form.descripcion,
      simbolo_uni: form.simbolo,
      tipo_uni_id: parseInt(form.tipoUnidad),
      created_by: "dasfdasd",
      created_date: new Date(),
      last_modified_by: "1",
      last_modified_date: new Date(),
      status: form.habilitado ? "SI" : "NO",
      tenantId: 1,
      eliminado: false,
    };
    if (editar) {
      updateUnidad(formatterForm)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Unidad modificada con exito",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se Pudo editar la Unidad",
            status: "error",
          });
        });
    } else {
      createUnidad(formatterForm)
        .then((res) => {
          setIsLoading(false);
          props.onClick({ msj: "Unidad creada con exito", status: "success", });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({ msj: "No se Pudo Crear la Unidad", status: "error" });
        });
    }
  };

  const titulo = () => {
    const titulo = ver
      ? "Ver unidad"
      : editar
        ? "Editar unidad"
        : "Crear unidad";
    return titulo;
  };

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!editar ? !formCompleto : false}
          />
        )}
      </>
    );
  };
  return (
    <StyledModalcomponent className="divModalItem">
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />
      <div className="unidad">
        <div className="divLabel">
          <p className="titulo"> {titulo()}</p>
        </div>
        <div className="divInputsItem">
          <form className="formulario" onSubmit={handleSubmit}>
            <div className="divPrimerInput">
              <Input
                value={form.codigo}
                label="Código"
                className="inputCodigo input"
                variant="outlined"
                size={"medium"}
                name="codigo"
                onChange={handleChange}
                disabled={ver && true}
              />
              <FormControlLabel
                control={
                  <Switch
                    name="habilitado"
                    checked={form.habilitado}
                    disabled={ver}
                    onChange={handleChange}
                  />
                }
                label="Habilitar"
              />
            </div>

            <Input
              value={form.descripcion}
              label="Descripción"
              className="inputDesc input"
              variant="outlined"
              size={"medium"}
              name="descripcion"
              onChange={handleChange}
              disabled={ver && true}
            />
            <Input
              reactSelect
              options={tipoUnidades}
              value={selectedOption}
              onChange={(e) => {
                handleChange(e, "tipoUnidad");
              }}
              isDisabled={ver && true}
              placeholder={ver ? "Tipo" : "Selecciona el tipo"}
            />
            <Input
              value={form.simbolo}
              label="Símbolo"
              className="inputSimbolo input"
              variant="outlined"
              size={"medium"}
              name="simbolo"
              disabled={ver && true}
              onChange={handleChange}
            />

            <div className="divButtonsItem">{renderButtons()}</div>
          </form>
        </div>
      </div>
    </StyledModalcomponent>
  );
};

export default Unidad;

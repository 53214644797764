import React, { useState, useEffect } from 'react';
import Tables from '../../components/Tables/Tables.jsx';
import HeaderTable from '../../components/HeaderTable/HeaderTable.jsx';
import Modal from "../../components/Modal/Modal";
import { buttonsDefault } from '../../components/ButtonsTable/ButtonsTable';
import styled from 'styled-components';
import { deleteProveedor, getProveedorePaginated, } from '../../api/Clientes.js';
import Toast from '../../components/Toast/Toast';
import Modal2 from "../../components/Modal/Modal";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const columns = [
  { id: "codigo", label: "Código" },
  { id: "denominacion", label: "Denominación" },
  { id: "email", label: "EMail" },
  { id: "telefono", label: "Teléfono" },
  { id: "buttons" },
]
const Proveedores = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");


  const [proveedores, setProveedores] = useState([]);
  const [proveedoreId, setProveedoresId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);

  useEffect(() => {
    getProveedorePaginated(paginaActual, orderBy, searchTerm).then((res) => {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      setProveedores(res.data.items.map((res) => {
        return ({ id: res.id, codigo: res.codigo_prv, direccion: res.direccion_prv, denominacion: res.desc_prv, email: res.emails_prv, telefono: res.telefonos_prv });
      }));
    })
  }, []);

  useEffect(() => {
    getProveedorePaginated(paginaActual, orderBy, searchTerm).then((res) => {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      setProveedores(res.data.items.map((res) => {
        return ({ id: res.id, codigo: res.codigo_prv, direccion: res.direccion_prv, denominacion: res.desc_prv, email: res.emails_prv, telefono: res.telefonos_prv });
      }));
    })
  }, [searchTerm, paginaActual, orderBy]);

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setProveedoresId(valueRow.id);
      setModalStatus2(true);
    }
  }
  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  }

  const handleDelete = (id) => {
    deleteProveedor(id)
      .then(() => {
        let paginaActualTemp = paginaActual;
        if (proveedores.length - 1 === 0 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        setProveedoresId("");
        return getProveedorePaginated(paginaActualTemp, orderBy, searchTerm);
      })
      .then(res => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setSuccess(true);
        setMsjToast("Proveedor eliminado con exito");
        setTimeout(() => {
          setSuccess(false);
          setProveedores(res.data.items.map((res) => {
            return ({ id: res.id, codigo: res.codigo_prv, direccion: res.direccion_prv, denominacion: res.desc_prv, email: res.emails_prv, telefono: res.telefonos_prv });
          }));
        }, 1500);
      })
      .catch(err => console.error("Error al eliminar un proveedor.", err));
  };
  return (
    <Styled>
      <div className="toast" >
        {success ? <Toast title={msjToast} close={() => setSuccess(false)} /> : null}
        {error ? <Toast type={"error"} title={msjToast} close={() => setError(false)} /> : null}</div>
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={() => {
          setModalStatus(false);
          getProveedorePaginated(paginaActual, orderBy, searchTerm).then((res) => {
            if(res.data.totalPages > pageCount) {
              getProveedorePaginated(paginaActual + 1, orderBy, searchTerm).then((res) => {
                setPaginaActual(paginaActual + 1)
                setPageCount(res.data.totalPages);
                setItemsCount(res.data.totalItems);
                setProveedores(res.data.items.map((res) => {
                  return ({
                    id: res.id,
                    codigo: res.codigo_prv,
                    direccion: res.direccion_prv,
                    denominacion: res.desc_prv,
                    direccion: res.direccion_prv,
                    email: res.emails_prv,
                    telefono: res.telefonos_prv
                  });
            }));
              })
            } else {
              setPageCount(res.data.totalPages);
              setItemsCount(res.data.totalItems);
              setProveedores(res.data.items.map((res) => {
                return ({
                  id: res.id,
                  codigo: res.codigo_prv,
                  direccion: res.direccion_prv,
                  denominacion: res.desc_prv,
                  direccion: res.direccion_prv,
                  email: res.emails_prv,
                  telefono: res.telefonos_prv
                });
              }));
            }
            

          })
        }}
        type={"proveedores"}
      // iconX={valueTicket === "Ver"}
      />
      <HeaderTable
        styledHead="proveedores"
        proveedores
        title="Proveedores"
        labelButton={"Nuevo proveedor"}
        valueBuscador={(value) => {
          setPaginaActual(1);
          setSearchTerm(value)
        }}
        onClickButton={() => { handleClickButtonHeader() }}
        selectInput={(e) => { setSearchTerm(e.target.value.label) }}
        buttonSet={() => { setSearchTerm("") }}
        value={searchTerm} 
        search
        isClearable={()=>{setSearchTerm("")}} 
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={proveedores}
        cRows={20}
        totalRows={itemsCount}
        pagination={true}
        onClickSwitch={(value) => { setOrderBy(value) }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        actualpage={paginaActual}
        // valueBuscador={valueBuscador}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => { handleClickButonTable(value, valuesRow) }}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDelete(proveedoreId);
        }}
      />

    </Styled>
  )
}

export default Proveedores

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables{
    //height: 100vh;
  }
`
import React, { useReducer, useState, useEffect } from "react";
import Input from "../../../../Input/Input.jsx";
import DropZone from "../../../../DropZone/DropZone.jsx";
import DropZoneTest from "../../../../DropZone/DropZoneTest.jsx";
import CustomButton from "../../../../Button/CustomButton.tsx";
import Preloader from "../../../../Preloader/Preloader.jsx";
import Toast from "../../../../Toast/Toast.jsx";
import { StyledModalcomponentClientes } from "../../../Styled.jsx";
import { createCliente, updateCliente } from "../../../../../api/Clientes.js";

const ClientesModal = (props) => {
  const propsClientes = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos };
  };
  const { ver, editar, datos } = propsClientes(props.datos);
  const [formCompleto, setFormCompleto] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [logo_cli, setLogo_cli] = useState(["", ""]);

  const [form, setForm] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_FIELD":
          return { ...state, [action.field]: action.value };
        default:
          return state;
      }
    },
    {
      id: datos.id ?? "",
      codigo: datos.codigo ?? "",
      denominacion: datos.denominacion ?? "",
      direccion: datos.direccion ?? "",
      email: datos.email ?? "",
      telefono: datos.telefono ?? "",
      logo: datos.logo ?? null,
    }
  );

  useEffect(() => {
    if (datos.logo !== "" && datos.logo !== undefined) {
      let auxbase65 = datos.logo.split(",");
      setLogo_cli([auxbase65[0], auxbase65[1]]);
    }
  }, []);

  useEffect(() => {
    const campoValido = (campo) => form[campo] !== "";

    const camposaValidar = [
      "codigo",
      "denominacion",
      "direccion",
      "email",
      "telefono",
      "logo",
    ];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === "checkbox" ? checked : value;
      setForm({ type: "SET_FIELD", field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
      // setSelectedOption(e);
      setForm({ type: "SET_FIELD", field: name, value: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formatterForm = {
      codigo_cli: form.codigo,
      tipo_cli: "tipo_de_proveedor",
      desc_cli: form.denominacion,
      inicio_cli: new Date(),
      emails_cli: form.email,
      telefonos_cli: form.telefono,
      tipo_doc_cli: "DNI",
      numero_doc_cli: "123456789",
      direccion_cli: form.direccion,
      complemento_dom_cli: "Complemento de domicilio",
      barrio_cli: "Barrio del proveedor",
      localidad_cli: "Localidad del proveedor",
      provincia_cli: "Mendoza",
      logo_cli: logo_cli[1],
      logo_cli_content_type: logo_cli[0],
      thumb_logo_cli: logo_cli[1],
      thumb_logo_cli_content_type: logo_cli[0],
      created_by: "usuario_creador",
      created_date: new Date(),
      last_modified_by: "usuario_modificador",
      last_modified_date: new Date(),
      status: form.habilitado ? "SI" : "NO",
      tenantId: 1,
      eliminado: false,
    };

    if (editar) {
      formatterForm.id = form.id;
      updateCliente(formatterForm)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Cliente modificado con exito.",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se pudo modificar el cliente.",
            status: "error",
          });
        });
    } else {
      createCliente(formatterForm)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Cliente creado con exito.",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          if(err.response.data.includes("Duplicate entry")){
            props.onClick({
              msj: "Codigo ya existente.",
              status: "error",
            });
          } else {
            props.onClick({
              msj: "No se pudo crear el cliente.",
              status: "error",
            });
          }
        });
    }
  };

  const titulo = () => {
    const titulo = ver
      ? "Ver cliente"
      : editar
      ? "Editar cliente"
      : "Crear o editar Clientes";
    return titulo;
  };

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!editar ? !formCompleto : false}
          />
        )}
      </>
    );
  };
  return (
    <StyledModalcomponentClientes>
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />

      <div classname="toast">
        {success ? (
          <Toast title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            type={"error"}
            title={
              "Error al " + (editar ? "modificar" : "crear") + "la unidad ?"
            }
            close={() => setError(false)}
          />
        ) : null}
      </div>

      <div className="clientes">
        <div className="divLabel">
          <p className="titulo"> {titulo()} </p>
        </div>
        <div className="divInputsItem">
          <form className="formulario" onSubmit={handleSubmit}>
            <Input
              value={form.codigo}
              label="Código"
              className="inputCodigo input"
              variant="outlined"
              size={"medium"}
              disabled={ver}
              type="number"
              name="codigo"
              onChange={handleChange}
            />
            <Input
              value={form.denominacion}
              label="Denominación"
              className="inputDesc inputDenominacion input"
              variant="outlined"
              size={"medium"}
              name="denominacion"
              disabled={ver}
              onChange={handleChange}
            />
            <Input
              value={form.direccion}
              label="Dirección"
              className="inputCodigo inputDireccion input"
              variant="outlined"
              size={"medium"}
              name="direccion"
              disabled={ver}
              onChange={handleChange}
            />
            <div className="divUltimoInputs">
              <Input
                value={form.email}
                label="Email"
                className="inputDesc inputEmail input"
                variant="outlined"
                size={"medium"}
                name="email"
                disabled={ver}
                type="email"
                onChange={handleChange}
              />
              <Input
                value={form.telefono}
                label="Teléfono"
                className="inputDesc inputTelefono input"
                variant="outlined"
                size={"medium"}
                name="telefono"
                //type="number"
                disabled={ver}
                onChange={handleChange}
              />
            </div>

            <DropZoneTest
              setImagen={setLogo_cli}
              imgDefault={form.logo}
              ver={ver}
            />

            <div className="divButtonsItem">{renderButtons()}</div>
          </form>
        </div>
      </div>
    </StyledModalcomponentClientes>
  );
};

export default ClientesModal;

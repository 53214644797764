import React, { useReducer, useState, useEffect } from 'react';
import { StyledModalcomponent } from '../../../Styled.jsx';
import Input from '../../../../Input/Input.jsx';
import CustomButton from '../../../../Button/CustomButton.tsx';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import {createTipoUnidad, updateTipoUnidad } from '../../../../../api/Configuracion';
// import Toast from '../../../../Toast/Toast.jsx';
import Preloader from '../../../../Preloader/Preloader.jsx';

const TipoUnidades = (props) => {
  const propsTipoUnidades = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos }
  }
  const { ver, editar, datos } = propsTipoUnidades(props.datos);

  const [formCompleto, setFormCompleto] = useState(false);
  // const [success, setSuccess] = useState(false);
  // const [error, setError] = useState(false);
  // const [msjToast, setMsjToast] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    id: datos.id ?? '',
    codigo: datos.codigo ?? '',
    descripcion: datos.descripcion ?? '',
    habilitado: datos.habilitado === "ACTIVE" || datos.habilitado === "SI" ? true : false
  });

  

  useEffect(() => {
    const campoValido = campo => form[campo] !== '' && (campo !== 'habilitado' || form[campo]);

    const camposaValidar = ['codigo', 'descripcion', 'habilitado'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);


  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (ver) {
      return
    }
    const fieldValue = name === 'habilitado' ? checked : value;
    setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formatterForm = {
      "id": form.id,
      "codigo_tpuni":form.codigo.toString(),
      "desc_tpuni": form.descripcion,
      "abrev_tpuni": "312321",
      "created_by": "llenar",//sacar usuario
      "created_date": new Date(),//formatear sysdate   
      "status": form.habilitado?"SI":"NO",
      "tenantId": 1,//sacar tenant
      "eliminado": false
    }

    if(editar){
      updateTipoUnidad(formatterForm).then(res => {
        setIsLoading(false);       
        props.onClick({msj:"Tipo unidad modificada con éxito", status:"success"});        
      }).catch((err)=>{
        setIsLoading(false);       
        props.onClick({msj:"No se Pudo editar el Tipo unidad", status:"error"});
      })

    }else{
      createTipoUnidad(formatterForm).then(res => {
       
        setIsLoading(false);    
        props.onClick({msj:"Tipo de Unidad creada con éxito", status:"success"});   
      }).catch((err)=>{
        setIsLoading(false);       
        props.onClick({msj:"No se Pudo crear el tipo de Unidad", status:"error"});
      })

    }
   
  }

  const titulo = () => {
    const titulo = ver ? "Ver tipo de unidad" : editar ? "Editar tipo unidad" : "Crear tipo de unidad";
    return titulo
  }
  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!editar ? !formCompleto : false}
          />
        )}
      </>
    );
  };
  return (
    <StyledModalcomponent>
       <Preloader title="Cargando..." subtitle={"Espere unos Segundos por favor..."} loading={isLoading} />
    {/* <div classname="toast" >      
      {success? <Toast title={msjToast} close={() => setSuccess(false)}/> : null}
      {error? <Toast type={"error"} title={msjToast} close={() => setError(false)}/> : null}
    </div>  */}
 
      <div className="tipoUnidades">
        <div className="divLabel">
          <p className="titulo"> {titulo()}</p>
        </div>
        <div className="divInputsItem">
          <form className="formulario" onSubmit={handleSubmit} >
            <div className="divPrimerInput">
              <Input
                value={form.codigo}
                label="Código"
                className="inputCodigo input"
                variant="outlined"
                size={"medium"}
                name="codigo"
                disabled={ver}
                onChange={handleChange}
              />
              <FormControlLabel
                control={<Switch name="habilitado" disabled={ver} checked={form.habilitado} onChange={handleChange} />}
                label="Habilitar"
              />
            </div>

            <Input
              value={form.descripcion}
              label="Descripción"
              className="inputDesc input"
              variant="outlined"
              size={"medium"}
              name="descripcion"              
              disabled={ver}
              onChange={handleChange}
            />


            <div className="divButtonsItem">
              {renderButtons()}
            </div>
          </form>


        </div>
      </div>
    </StyledModalcomponent>
  )
}

export default TipoUnidades
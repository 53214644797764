import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Styled, StyledTypography } from "./CausasDeFallaStyled.js";
import { Search } from "@mui/icons-material";
import { RenderImgStyled } from "../../../Task/Items/SuperAdminStyled";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import {
  getCausasDeFallasSearch,
  getCausasDeFallasPaginated,
  deleteCausasDeFallas,
} from "../../../../api/Configuracion.js";
import Input from "../../../../components/Input/Input";
import CustomButton from "../../../../components/Button/CustomButton.tsx";
import More from "../../../../assets/Icons/Buttons/More.svg";
import Tables from "../../../../components/Tables/Tables.jsx";
import Modal from "../../../../components/Modal/Modal.jsx";
import Modal2 from "../../../../components/Modal/Modal.jsx";
import Toast from "../../../../components/Toast/Toast.jsx";
import { CircularProgress } from "@mui/material";
const columnsDefaultCfg = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "tipo", label: "Tipo" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
];

const CausasDeFalla = () => {
  const [datosModal, setDatosModal] = useState("");
  const [statusModal, setModalStatus] = useState(false);
  const [statusModal2, setModalStatus2] = useState(false);
  const [causasDeFalla, setCausasDeFalla] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [paginaActual, setPaginaActual] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [causasDeFallasId, setCausasDeFallasId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState(0);

  //Datos al modal
  useEffect(() => {
    // getCausasDeFallasPaginated(paginaActual, orderBy, searchTerm)
    //   .then((res) => {
    //     setPageCount(res.data.totalPages);
    //     setItemsCount(res.data.totalItems);
    //     setCausasDeFalla(
    //       res.data.items.map((res) => {
    //         return {
    //           id: res.id,
    //           codigo: res.codigo_falla,
    //           descripcion: res.desc_falla,
    //           tipo: res.tipo_falla_id.description,
    //           tipoId: res.tipo_falla_id.id,
    //           habilitado: res.status,
    //         };
    //       })
    //     );
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.error("Error al obtener causas de fallas:", err);
    //   });
    fetchCausasDeFallasPaginated(paginaActual, orderBy, searchTerm, "primera");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginaActual, orderBy]); // Ignorando fetchTiposEventosPaginated y searchTerm en la dependencia array

  //Buscador
  // useEffect(() => {
  //   if (searchTerm === "") {
  //     getCausasDeFallasPaginated(paginaActual, orderBy, searchTerm)
  //       .then((res) => {
  //         setPageCount(res.data.totalPages);
  //         setItemsCount(res.data.totalItems);
  //         setCausasDeFalla(
  //           res.data.items.map((res) => {
  //             return {
  //               id: res.id,
  //               codigo: res.codigo_falla,
  //               descripcion: res.desc_falla,
  //               tipo: res.tipo_falla_id.description,
  //               tipoId: res.tipo_falla_id.id,
  //               habilitado: res.status,
  //             };
  //           })
  //         );
  //       })
  //       .catch((err) => {
  //         console.error("Error al obtener causas de fallas:", err);
  //       });
  //   } else {
  //     getCausasDeFallasSearch(searchTerm)
  //       .then((res) => {
  //         if (res.data && res.data.items && res.data.items.length > 0) {
  //           setPageCount(1);
  //           setItemsCount(res.data.items.length);
  //           setCausasDeFalla(
  //             res.data.items.map((res) => {
  //               console.log(res);
  //               return {
  //                 id: res.id,
  //                 codigo: res.codigo_falla,
  //                 descripcion: res.desc_falla,
  //                 tipo: res.tipo_falla_id.description,
  //                 habilitado: res.status,
  //               };
  //             })
  //           );
  //         } else {
  //           setCausasDeFalla([]);
  //           setPageCount(0);
  //           setItemsCount(0);
  //         }
  //       })
  //       .catch((err) => {
  //         console.error("Error al obtener causa de fallas:", err);
  //       });
  //   }
  // }, [searchTerm]);

  const handleClickButtonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
      setModalStatus(true);
    } else {
      setDatosModal("");
      setCausasDeFallasId(valueRow.id);
      setModalStatus2(true);
    }
    if (value === "Crear") {
      setDatosModal("");
      setModalStatus(true);
    }
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }
    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  const handleDeleteCausasDeFalla = (causasDeFallasId) => {
    setIsLoading(true);
    setModalStatus2(false);
    deleteCausasDeFallas(causasDeFallasId)
      .then(() => {
        setIsLoading(false);
        setCausasDeFallasId("");
        let paginaActualTemp = paginaActual;
        if (causasDeFalla.length === 1 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        return fetchCausasDeFallasPaginated(
          paginaActualTemp,
          "denominacion",
          searchTerm,
          "delete"
        );
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setMsjToast("Error al eliminar causa de fallas.");
        setError(true);
      });
  };

  const fetchCausasDeFallasPaginated = async (
    pagina,
    order,
    search,
    action
  ) => {
    const res = await getCausasDeFallasPaginated(pagina, order, search);

    setPageCount(res.data.totalPages);
    setItemsCount(res.data.totalItems);

    if (action === "new") {
      if (res.data.items.length + 1 === 21) {
        setPaginaActual(res.data.totalPages);
      }
    } else if (action === "delete") {
      setKey(key + 1);
      setMsjToast("Causa de fallas eliminado con exito.");
      setSuccess(true);
    } else {
      if (res.data.totalPages === 0) {
        setPaginaActual(1);
      } else if (res.data.totalPages < paginaActual) {
        setPaginaActual(res.data.totalPages);
      } else {
        setPaginaActual(paginaActual);
      }
    }

    setCausasDeFalla(
      res.data.items.map((res) => {
        // console.log(res);
        return {
          id: res.id,
          codigo: res.codigo_falla,
          descripcion: res.desc_falla,
          tipo: res.tipo_falla_id.description,
          tipoId: res.tipo_falla_id.id,
          habilitado: res.status,
        };
      })
    );
  };

  const handleBuscador = (value) => {
    setSearchTerm(value);
    fetchCausasDeFallasPaginated(paginaActual, orderBy, value, "search");
  };

  return (
    <Styled>
      {isLoading && (
        <CircularProgress
          size={30}
          sx={{
            position: "fixed",
            bottom: "0",
            padding: "10px",
            zIndex: "9999",
          }}
        />
      )}
      {success && (
        <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
      )}
      {error && (
        <Toast
          key={key}
          type={"error"}
          title={msjToast}
          close={() => setError(false)}
        />
      )}

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={"causasDeFallas"}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            setKey(key + 1);
            setSuccess(true);
            setMsjToast(msj);
            fetchCausasDeFallasPaginated(
              paginaActual,
              orderBy,
              searchTerm,
              msj.includes("cread") ? "new" : ""
            );
          } else if (status === "error") {
            // setKey(key+1);
            setError(true);
            setMsjToast(msj);
          } else if (status === "cancel") {
            fetchCausasDeFallasPaginated(
              paginaActual,
              orderBy,
              searchTerm,
              msj.includes("cread") ? "new" : ""
            );
          }
          setModalStatus(false);
        }}
      />

      <StyledTypography>Causas de Falla</StyledTypography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 0px",
        }}
      >
        <Box sx={{ width: "30%" }}>
          <Input
            inputProp={true}
            positionIcon={"start"}
            placeholder={"Buscar por código, descripción, etc"}
            icon={<Search />}
            size={"small"}
            onChange={(e) => {
              handleBuscador(e.target.value);
            }}
            className="inputComponent"
            value={searchTerm}
            search
            isClearable={() => {
              handleBuscador("");
            }}
            style={{ borderRadius: "10px", paddingLeft: "0px" }}
          />
        </Box>
        <CustomButton
          label={"Nueva causa de falla"}
          startIcon={<RenderImgStyled img={More} />}
          variant={"contained"}
          className="buttonHeaderTable"
          onClick={() => {
            handleClickButtonTable("crear");
          }}
        />
      </Box>

      <Tables
        typeTable={"prop"}
        columns={columnsDefaultCfg}
        rows={causasDeFalla.length > 0 ? causasDeFalla : []}
        cRows={10}
        totalRows={itemsCount}
        actualpage={paginaActual}
        pagination={true}
        tableColor={false}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButtonTable(value, valuesRow);
        }}
      />

      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteCausasDeFalla(causasDeFallasId);
        }}
      />
    </Styled>
  );
};

export default CausasDeFalla;

import React, { useState, useEffect, useRef } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import styled from "styled-components";
import QrIcon from "../../../../assets/Icons/Buttons/Clientes/QrIcon.svg";
import RenderImg from "../../../../components/RenderImg/RenderImg.tsx";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Toast from "../../../../components/Toast/Toast";
import Modal2 from "../../../../components/Modal/Modal";
import Input from "../../../../components/Input/Input";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import {
  getPlantaOptions,
  deleteEquipos,
  getEquiposPaginated,
  getEquiposSearch,
  getEquiposByPlantas,
} from "../../../../api/Clientes.js";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const columns = [
  { id: "codigo", label: "Código" },
  { id: "denominacion", label: "Denominación" },
  { id: "tagplanta", label: "TAG Planta" },
  { id: "planta", label: "Planta" },
  { id: "buttons" },
];
const button = {
  titleToolTip: "Ptos.Toma",
  icon: <RenderImg img={QrIcon} style={{ padding: "5px" }} />,
  className: "buttonClientes",
};
const buttonsHeadTable = [
  {
    label: "Plantas",
    icon: <ArrowBackIcon />,
    classname: "buttonColorTable",
    navigate: "/clientes/plantas",
    size: "medium",
  },
  {
    label: "Puntos de toma",
    icon: <ArrowForwardIcon />,
    classname: "buttonColorTable",
    navigate: "/clientes/puntosdetoma",
    size: "medium",
  },
  {
    label: "Nuevo equipo",
    classname: "buttonHeaderTable",
  },
];
let datosInputsHeaderTable = [
  {
    classnamediv: "divInputSelect",
    className: "inputSelect",
    positionIcon: "start",
    placeholder: "placeholder",
    size: "small",
    name: "hola",
    datosSelect: [
      { label: "primero", value: "1" },
      { label: "segundo", value: "2" },
      { label: "tercero", value: "3" },
    ],
  },
];

const Equipos = () => {
  const { state } = useLocation();
  const row = state ? state.row : null;
  const Navigate = useNavigate();

  const combinedButtons = [button, ...buttonsDefault];
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [equipos, setEquipos] = useState([]);
  const [equipoId, setEquipoId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [plantas, setPlantes] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [key, setKey] = useState(1);
  const [prefilter, setPrefilter] = useState(row ? true : false);
  const [firstRender, setFirstRender] = useState(true);
  const prevStateRef = useRef({ paginaActual, orderBy, searchTerm });

  // useEffect(() => {
  //   datosInputsHeaderTable = [
  //     {
  //       classnamediv: "divInputSelect",
  //       className: "inputSelect",
  //       positionIcon: "start",
  //       placeholder: "Planta",
  //       size: "small",
  //       name: "hola",
  //       //input
  //       datosSelect: plantas,
  //     },
  //   ];
  // }, [plantas]);

  // useEffect(() => {
  //   if (firstRender) {
  //     setFirstRender(false);
  //     return;
  //   }

  //   if (prefilter) {
  //     let id = row ? row.id : selectedOption.value;
  //     fetchPlantaByIdPaginated(id, paginaActual, orderBy, searchTerm, "search");
  //   } else {
  //     fetchEquiposPaginated(paginaActual, orderBy, searchTerm, "primera");
  //   }
  // }, [paginaActual, orderBy, searchTerm]);

  useEffect(() => {
    getPlantaOptions().then((res) => {
      setPlantes(
        res.data.map((res) => {
          return { label: res.desc_pla, value: res.id };
        })
      );
    });

    if (prefilter) {
      setSelectedOption({ label: row.denominacion, value: row.id });
      fetchPlantaByIdPaginated(
        row.id,
        paginaActual,
        orderBy,
        searchTerm,
        "search"
      );
    } else {
      fetchEquiposPaginated(paginaActual, orderBy, searchTerm, "primera");
    }
  }, []);

  /*useEffect(() => {
    fetchEquiposPaginated(paginaActual, orderBy, searchTerm, "primera");
  }, [paginaActual]);*/

  useEffect(() => {
    if(selectedOption !== null && selectedOption !== '') {
      // busco por equipo + search
      fetchEquiposPaginated(paginaActual, orderBy, searchTerm, "byPlanta")
    } else {
      // busco solo por search
      fetchEquiposPaginated(paginaActual, orderBy, searchTerm)
    }
  }, [selectedOption, paginaActual, orderBy, searchTerm])

  const fetchEquiposPaginated = async (pagina, order, search, action) => {
    const res = action === "byPlanta" || (selectedOption !== null && selectedOption !== "") ? await getEquiposByPlantas(selectedOption.value, pagina, order, search) : await getEquiposSearch(pagina, order, search);

    if (action !== "") {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      if (res.data.totalPages > 0) {
        setPaginaActual(
          res.data.totalPages < paginaActual
            ? res.data.totalPages
            : paginaActual
        );
      }
    }

    if (action === "new" && equipos.length + 1 === 21) {
      setPaginaActual(res.data.totalPages);
    }

    if (action === "delete") {
      //setKey(key + 1);
      setMsjToast("Equipo eliminado con exito.");
      setSuccess(true);
    }
  let filtroSeleccionado = ""
  if(selectedOption !== null && selectedOption !== '') {
    filtroSeleccionado = selectedOption
  }
    setEquipos(
      res.data.items.map((res) => {
        return {
          id: res.id,
          codigo: res.codigo_equi,
          denominacion: res.desc_equi,
          tagplanta: res.tag_externo_equi,
          planta: res.planta_id.description,
          plantaId: res.planta_id.id,
          area: res.area_equi,
          cliente: res.planta_id.cliente.description,
          clienteId: res.planta_id.cliente.id,
          filtro: filtroSeleccionado
        };
      })
    );
  };

  const fetchPlantaByIdPaginated = async (
    id,
    pagina,
    order,
    search,
    action
  ) => {
    const res = await getEquiposByPlantas(id, pagina, order, search);
    if (action !== "") {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      if (res.data.totalPages > 0) {
        setPaginaActual(
          res.data.totalPages < paginaActual
            ? res.data.totalPages
            : paginaActual
        );
      }
    }

    if (action === "new" && equipos.length + 1 === 21) {
      setPaginaActual(res.data.totalPages);
    }

    if (action === "delete") {
      //setKey(key + 1);
      setMsjToast("Equipo eliminado con exito.");
      setSuccess(true);
    }

    setEquipos(
      res.data.items.map((res) => {
        return {
          id: res.id,
          codigo: res.codigo_equi,
          denominacion: res.desc_equi,
          tagplanta: res.tag_externo_equi,
          planta: res.planta_id.description,
          plantaId: res.planta_id.id,
          area: res.area_equi,
          cliente: res.planta_id.cliente.description,
          clienteId: res.planta_id.cliente.id
        };
      })
    );
  };

  const handleClickButonTable = (value, valueRow) => {
    if (value === "Ptos.Toma") {
      Navigate(`/clientes/puntosdetoma`, {
        state: { ruta: value, row: valueRow },
      });
    }
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setEquipoId(valueRow.id);
      setModalStatus2(true);
    }
  };

  const handleDeleteEquipo = (id) => {
    deleteEquipos(id)
      .then(() => {
        setEquipoId("");
        let paginaActualTemp = paginaActual;
        if (equipos.length - 1 === 0 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        return fetchEquiposPaginated(
          paginaActualTemp,
          "denominacion",
          searchTerm,
          "delete"
        );
      })
      .catch((err) => {
        // setKey(key + 1);
        setMsjToast("Error al eliminar el equipo.");
        setError(true);
      });
  };

  const handleChangePlanta = (value) => {
    if (!value) {
      setPrefilter(false);
      //fetchEquiposPaginated(paginaActual, orderBy, searchTerm, "plantas");
    } else {
      /*fetchPlantaByIdPaginated(
        value.value,
        paginaActual,
        orderBy,
        searchTerm,
        "plantas"
      );*/
      setPrefilter(true);
    }
  };
  const handleBuscador = (value) => {
    setSearchTerm(value);
    if (prefilter) {
      /*let id = row ? row.id : selectedOption.value;
      fetchPlantaByIdPaginated(id, paginaActual, orderBy, value, "search");*/
    } else {
      //fetchEquiposPaginated(paginaActual, orderBy, value, "search");
    }
  };

  const handleClickButtonHeader = () => {
    if(selectedOption !== null || selectedOption !== '') {
      setDatosModal({ selectedOption });
    }else{
        setDatosModal("");
    }

   setModalStatus(true);
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  return (
    <Styled>
      <div className="toast">
        {success ? (
          <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            key={key}
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        ) : null}
      </div>

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={"equipos"}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            // setKey(key + 1);
            setSuccess(true);
            setMsjToast(msj);
            fetchEquiposPaginated(
              paginaActual,
              orderBy,
              searchTerm,
              msj.includes("cread") ? "new" : ""
            );
          } else if (status === "error") {
            // setKey(key + 1);
            setError(true);
            setMsjToast(msj);
          }
          setModalStatus(false);
        }}
      />

      <div className="compexterno">
        <HeaderTable
          styledHead="clientes"
          clientes
          title="Equipos"
          isCleareable
          datosButtons={buttonsHeadTable}
          valueBuscador={(value) => handleBuscador(value)}
          onClickButton={() => {
            handleClickButtonHeader();
          }}
          multiInputs
          selectInput={(e) => {
            setSearchTerm(e.label);
          }}
          buttonSet={() => {
            setSearchTerm("");
          }}
          clean
          search
          value={searchTerm}
          isClearable={() => {
            setSearchTerm("")  
            //setPaginaActual(1);
            //setOrderBy(""); 
            //fetchEquiposPaginated(1, "", "", "reset"); 
          }}
        />
        <div className="compinterno">
          <Input
            isClearable
            reactSelect
            placeholder={"Seleccione Planta"}
            options={plantas}
            stylesHeader
            onChange={(e) => {
              if (e === null) {
                setSelectedOption("");
                setSearchTerm("");
                handleChangePlanta(e);
              } else {
                setSelectedOption(e);
                handleChangePlanta(e);
              }
            }}
            value={selectedOption}
          />
        </div>
      </div>

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={equipos}
        cRows={20}
        totalRows={itemsCount}
        actualpage={paginaActual}
        pagination={true}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        buttons={combinedButtons}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      //valueBuscador={valueBuscador}
      //onClickSwitch={(id)=> alert(id)}
      />

      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteEquipo(equipoId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default Equipos;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
    // height: 100vh;
  }

  & .compexterno {
    position: relative;
  }
  & .compinterno {
    width: 300px;
    position: absolute;
    left: 29%;
    top: 55%;
  }
`;

import { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import styled from "styled-components";

export default function DropZoneTest(props) {
  const [hasImage, setHasImage] = useState(false);
  const [img_default, setImg_default] = useState(null);

  useEffect(() => {
    if (props.imgDefault) {
      setHasImage(true);
      setImg_default(props.imgDefault);
    } else {
      setHasImage(false);
    }
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    // console.log("acceptedFiles: ", acceptedFiles);

    const file = acceptedFiles[0];
    const fileSizeInBytes = file.size;
    const fileSizeInKB = fileSizeInBytes / 1024;
    const fileSizeInMB = fileSizeInKB / 1024;

    if (fileSizeInMB <= 3) {
      setHasImage(true);
      const reader = new FileReader();
      reader.onload = () => {
        let auxbase65 = reader.result.split(",");
        props.setImagen([auxbase65[0], auxbase65[1]]);
        setImg_default(reader.result);
        //props.setLogo_cli(auxbase65[1]);
        //props.setImagen(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("El archivo supera el tamaño permitido (3MB)");
    }
  }, []);

  const onRemove = () => {
    setHasImage(false);
    props.setImagen(["", ""]);
  };

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept: {
        "image/jpeg": [".jpeg"],
        "image/png": [".png"],
      },
      onDrop,
    });

  return (
    <Styled>
      <div className="dropzone">
        {hasImage ? (
          <div className="divFile">
            {props.imgDefault ? (
              <img src={img_default} alt="Uploaded" />
            ) : (
              <img src={URL.createObjectURL(acceptedFiles[0])} alt="Uploaded" />
            )}
            {!props.ver ? (
              <DeleteOutlinedIcon className="deleteIcon" onClick={onRemove} />
            ) : null}
          </div>
        ) : (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="divIcon">
              <UploadFileIcon fontSize="medium" />
            </div>

            {isDragActive ? (
              <p className="label">Suelta los archivos aquí ...</p>
            ) : (
              <div className="divButton">
                <p className="button label">Click para buscar archivo</p>
                <p className="label"> o arrastrelo y sueltelo aquí.</p>
              </div>
            )}

            <div className="divSub">
              <p className="sub">PNG, JPG (max. 3MB)</p>
            </div>
          </div>
        )}
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  /* padding:20px 0px; */
  /* <input {...getInputProps()} /> */
  .title {
    position: absolute;
    z-index: 1;
    background: white;
    top: 48px;
    left: 30px;
    color: #545454;
    font-family: Inter;
    font-weight: bold;
    font-size: 12px;
  }
  .dropzone {
    cursor: pointer;
    border: 2px dashed #afccd5;
    padding: 20px;
    text-align: center;
    position: relative;
    z-index: 0;
  }
  .divIcon {
    svg {
      color: #2196f3;
    }
  }
  .divButton {
    display: flex;
    align-items: center;
    justify-content: center;
    .button {
      text-decoration: underline;
      color: #2196f3;
    }
    .label {
      font-family: Inter;
      font-weight: bold;
      margin: 0px;
      margin-left: 5px;
      font-size: 12px;
    }
  }
  .divSub {
    .sub {
      color: #b0b0b0;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
    }
  }
  .divFile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 200px;
    height: 200px;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .text-overlay {
    position: absolute; /* Hace que la posición del texto sea absoluta en relación con el contenedor */
    top: 50%; /* Coloca el texto en el centro vertical del contenedor */
    left: 50%; /* Coloca el texto en el centro horizontal del contenedor */
    transform: translate(-50%, -50%); /* Centra el texto completamente */
    color: #000; /* Establece el color del texto */
  }
`;

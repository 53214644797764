import { Box, styled, Typography } from "@mui/material";
import { fonts, colors } from "../../../../assets/styles/theme.ts";

export const Styled = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  height: 100%;
`;

export const StyledTypography = styled(Typography)`
  font-family: ${fonts.primary};
  color: ${colors.blueDark};
  font-weight: 600;
  font-size: 20px ;
`;
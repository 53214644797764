import React, { useState, useEffect, useRef } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import styled from "styled-components";
import PlantasIcon from "../../../../assets/Icons/Buttons/Clientes/PlantasIcon.svg";
import RenderImg from "../../../../components/RenderImg/RenderImg.tsx";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Toast from "../../../../components/Toast/Toast";
import Modal2 from "../../../../components/Modal/Modal";
import Input from "../../../../components/Input/Input";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import {
  getClienteOptions,
  deletePlanta,
  getPlantaPaginated,
  getPlantaSearch,
  getPlantabyCliente,
} from "../../../../api/Clientes.js";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const columns = [
  { id: "codigo", label: "Código" },
  { id: "denominacion", label: "Denominación" },
  { id: "cliente", label: "Cliente" },
  { id: "buttons" },
];

const button = {
  titleToolTip: "Equipos",
  icon: <RenderImg img={PlantasIcon} style={{ padding: "5px" }} />,
  className: "buttonClientes",
};

const buttonsHeadTable = [
  {
    label: "Clientes",
    icon: <ArrowBackIcon />,
    classname: "buttonColorTable",
    navigate: "/clientes/clientes",
    size: "medium",
  },
  {
    label: "Equipos",
    icon: <ArrowForwardIcon />,
    classname: "buttonColorTable",
    navigate: "/clientes/equipos",
    size: "medium",
  },
  {
    label: "Nueva planta",
    classname: "buttonHeaderTable",
  },
];

let datosInputsHeaderTable = [
  {
    classnamediv: "divInputSelect",
    className: "inputSelect",
    positionIcon: "start",
    placeholder: "Cliente",
    size: "small",
    name: "hola",
    //input
    datosSelect: [
      { label: "primero", value: "1" },
      { label: "segundo", value: "2" },
      { label: "tercero", value: "3" },
    ],
  },
];

const Plantas = () => {
  const Navigate = useNavigate();
  const { state } = useLocation();
  const row = state ? state.row : null;

  const combinedButtons = [button, ...buttonsDefault];
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [plantas, setPlantas] = useState([]);
  const [plantaId, setPlantaId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [action, setAction] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [key, setKey] = useState(1);
  const [prefilter, setPrefilter] = useState(row ? true : false);
  const [firstRender, setFirstRender] = useState(true);
  const prevStateRef = useRef({ paginaActual, orderBy, searchTerm });

  const fetchPlantasPaginated = async (pagina, order, search, action) => {
    const res =
      search === ""
        ? await getPlantaPaginated(pagina, order)
        : await getPlantaSearch(pagina, order, search);

    if (action !== "") {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      if (res.data.totalPages > 0) {
        setPaginaActual(
          res.data.totalPages < paginaActual
            ? res.data.totalPages
            : paginaActual
        );
      }
    }
    if (action === "new" && plantas.length + 1 === 21) {
      setPaginaActual(res.data.totalPages);
    }

    if (action === "delete") {
      setKey(key + 1);
      setMsjToast("Planta eliminada con exito.");
      setSuccess(true);
    }

    let filtroSeleccionado = ""
    if(selectedOption !== null && selectedOption !== '') {
      filtroSeleccionado = selectedOption
    }

    setPlantas(
      res.data.items.map((res) => {
        return {
          emails: res.email_info_pla,
          id: res.id,
          codigo: res.codigo_pla,
          denominacion: res.desc_pla,
          cliente: res.cliente_id.description,
          filtro: filtroSeleccionado,
        };
      })
    );
  };

  const fetchPlantaByClientePaginated = async (
    id,
    pagina,
    order,
    search,
    action
  ) => {
    const res = await getPlantabyCliente(id, pagina, order, search);

    if (action !== "") {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      if (res.data.totalPages > 0) {
        setPaginaActual(
          res.data.totalPages < paginaActual
            ? res.data.totalPages
            : paginaActual
        );
      }
    }

    if (action === "new" && plantas.length + 1 === 21) {
      setPaginaActual(res.data.totalPages);
    }

    if (action === "delete") {
      setKey(key + 1);
      setMsjToast("Planta eliminada con exito.");
      setSuccess(true);
    }
    setPlantas(
      res.data.items.map((res) => {
        return {
          emails: res.email_info_pla,
          id: res.id,
          codigo: res.codigo_pla,
          denominacion: res.desc_pla,
          cliente: res.cliente_id.description,
        };
      })
    );
  };

  useEffect(() => {
    datosInputsHeaderTable = [
      {
        classnamediv: "divInputSelect",
        className: "inputSelect",
        positionIcon: "start",
        placeholder: "Cliente",
        size: "small",
        name: "hola",
        //input
        datosSelect: clientes,
      },
    ];
  }, [clientes]);

  useEffect(() => {
    getClienteOptions().then((res) => {
      setClientes(
        res.data.map((res) => {
          return { label: res.desc_cli, value: res.id };
        })
      );
    });

    if (row) {
      setSelectedOption({ label: row.denominacion, value: row.id });
      fetchPlantaByClientePaginated(
        row.id,
        paginaActual,
        orderBy,
        searchTerm,
        "search"
      );
      datosInputsHeaderTable = [
        {
          classnamediv: "divInputSelect",
          className: "inputSelect",
          positionIcon: "start",
          placeholder: "Cliente",
          size: "small",
          name: "hola",
          //input
          datosSelect: [{ label: row.denominacion, value: row.id }],
        },
      ];
    } else {
      fetchPlantasPaginated(paginaActual, orderBy, "", "primera");
    }
  }, []);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }

    if (prefilter) {
      let id = row ? row.id : selectedOption.value;
      fetchPlantaByClientePaginated(
        id,
        paginaActual,
        orderBy,
        searchTerm,
        "primera"
      );
    } else {
      fetchPlantasPaginated(paginaActual, orderBy, searchTerm, "primera");
    }
  }, [paginaActual]);

  const handleClickButonTable = (value, valueRow) => {
    if (value === "Equipos") {
      Navigate(`/clientes/equipos`, { state: { ruta: value, row: valueRow } });
    }
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setPlantaId(valueRow.id);
      setModalStatus2(true);
    }
  };

  const handleChangeCliente = (value) => {
    if (!value) {
      setSelectedOption(null);
      setPrefilter(false);
      fetchPlantasPaginated(paginaActual, orderBy, searchTerm, "clientes");
    } else {
      setSelectedOption(value);
      fetchPlantaByClientePaginated(
        value.value,
        paginaActual,
        orderBy,
        searchTerm,
        "clientes"
      );
      setPrefilter(true);
    }
  };

  const handleBuscador = (value) => {
    setSearchTerm(value);
    if (prefilter) {
      let id = selectedOption.value;
      fetchPlantaByClientePaginated(id, paginaActual, orderBy, value, "search");
    } else {
      fetchPlantasPaginated(paginaActual, orderBy, value, "search");
    }
  };

  const handleClickButtonHeader = () => {
    if(selectedOption !== null || selectedOption !== '') {
      setDatosModal({ selectedOption });
    }else{
      setDatosModal("");
    }

    setModalStatus(true);
  };

  const handleDeletePlanta = (id) => {
    deletePlanta(id).then(() => {
      setPlantaId("");
      let paginaActualTemp = paginaActual;
      if (plantas.length === 1 && paginaActualTemp > 1) {
        paginaActualTemp = paginaActualTemp - 1;
        setPaginaActual(paginaActualTemp);
      }

      if (prefilter) {
        let id = row ? row.id : selectedOption.value;
        return fetchPlantaByClientePaginated(
          id,
          paginaActualTemp,
          orderBy,
          searchTerm,
          "delete"
        );
      } else {
        return fetchPlantasPaginated(
          paginaActualTemp,
          "denominacion",
          searchTerm,
          "delete"
        );
      }
    });
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  return (
    <Styled>
      <div classname="toast">
        {success ? (
          <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            key={key}
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        ) : null}
      </div>

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={"plantas"}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            //setKey(key + 1);
            setSuccess(true);
            setMsjToast(msj);
            if (prefilter) {
              let id = row ? row.id : selectedOption.value;
              fetchPlantaByClientePaginated(
                id,
                paginaActual,
                orderBy,
                searchTerm,
                msj.includes("cread") ? "new" : ""
              );
            } else {
              fetchPlantasPaginated(
                paginaActual,
                orderBy,
                searchTerm,
                msj.includes("cread") ? "new" : ""
              );
            }
          } else if (status === "error") {
            //setKey(key + 1);
            setError(true);
            setMsjToast(msj);
          }
          setModalStatus(false);
        }}
      />
      <div className="compexterno">
        <HeaderTable
          styledHead="clientes"
          clientes
          title="Plantas"
          datosButtons={buttonsHeadTable}
          valueBuscador={(value) => handleBuscador(value)}
          onClickButton={() => {
            handleClickButtonHeader();
          }}
          multiInputs
          selectInput={(e) => {
            setSearchTerm(e.label);
          }}
          buttonSet={() => {
            setSearchTerm("");
          }}
          clean
          search
          value={searchTerm}
          isClearable={() => {
            setSearchTerm("");
            fetchPlantasPaginated(paginaActual, "denominacion", "", "reset");
          }}
        />
        <div className="compinterno">
          <Input
            isClearable
            reactSelect
            placeholder={"Seleccione Cliente"}
            options={clientes}
            stylesHeader
            onChange={(e) => {
              handleChangeCliente(e);
              // if (e === null) {
              //   setSelectedOption("");
              //   setSearchTerm("");
              //   handleChangeCliente(e);
              // } else {
              //   setSelectedOption(e);
              //   handleChangeCliente(e);
              // }
            }}
            value={selectedOption}
          />
        </div>
      </div>

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={plantas}
        cRows={20}
        totalRows={itemsCount}
        actualpage={paginaActual}
        pagination={true}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        buttons={combinedButtons}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      // valueBuscador={valueBuscador}
      // onClickSwitch={(id)=> alert(id)}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeletePlanta(plantaId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default Plantas;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
    //height: 100vh;
  }

  & .compexterno {
    position: relative;
    .divInput {
      padding: 0px !important;
    }
  }
  & .compinterno {
    width: 300px;
    position: absolute;
    left: 29%;
    top: 55%;
  }
`;

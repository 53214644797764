import RenderImg from "../../../components/RenderImg/RenderImg.tsx";
import { Box, Typography, styled } from "@mui/material";
import { colors, fonts } from "../../../assets/styles/theme.ts";
export { default as Input } from "../../../components/Input/Input";
export { default as CustomButton } from "../../../components/Button/CustomButton.tsx";
export { default as More } from "../../../assets/Icons/Buttons/More.svg";

export const Styled = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
  height: 95%;
`;
export const DivHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .divInput {
    width: 40%;
  }
`;
export const Title = styled(Typography)`
  color: ${colors.blueDark};
  font-family: ${fonts.primary};
  font-weight: 600;
  font-size: 20px;
`;
export const DivInputButton = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
export const RenderImgStyled = styled(RenderImg)`
  padding: 5px;
  height: 15px;
`;

export const RenderInput = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0px;
  .col,
  .coll,
  .colll,
  .collll {
    display: flex;
    gap: 10px;
    .divCodigo {
      width: 20%;
    }
    .divDenominacion {
      width: 80%;
    }
    .divDireccion {
      width: 60%;
    }
    .divTelefono {
      width: 40%;
    }
    .divEmail{
      width: 100%;
    }
    .divPwb{
      width: 70%;
    }
    .divTimagen{
      width: 30%;
    }
  }
  .colllll{
    display: flex;
    flex-direction: column;
    gap: 20px;
    .dropzone{
      padding: 10px 15px;
    }
    .divInputsEnd{
      display: flex;
      flex-direction: column;
    }
  }
  .divButtons{
    display:flex;
    justify-content: center;
    gap: 10px;
    @media screen and (max-width: 768px) {
     button{ 
      width: 100%;
    }
    
  }
  }
`;

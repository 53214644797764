import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import {
  deleteLubricante,
  getLubricantesPaginated,
  getLubricantesSearch,
} from "../../../../api/Configuracion";
import Toast from "../../../../components/Toast/Toast";
import Modal2 from "../../../../components/Modal/Modal";
import { CircularProgress } from "@mui/material";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import styled from "styled-components";
const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "tipo", label: "Tipo" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
];
const Lubricantes = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [lubricantes, setLubricantes] = useState([]);
  const [lubricanteId, setLubricantesId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getLubricantesPaginated(paginaActual, orderBy, searchTerm).then((res) => {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      setLubricantes(
        res.data.items.map((res) => {
          return {
            id: res.id,
            codigo: res.codigo_lub,
            descripcion: res.desc_lub,
            habilitado: res.status,
            tipo: res.tipo_lub_id.desc_tplub,
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    getLubricantesPaginated(paginaActual, orderBy, searchTerm).then((res) => {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      // alert(JSON.stringify(res.data))
      setLubricantes(
        res.data.items.map((res) => {
          return {
            id: res.id,
            codigo: res.codigo_lub,
            descripcion: res.desc_lub,
            habilitado: res.status,
            tipo: res.tipo_lub_id.desc_tplub,
          };
        })
      );
    });
  }, [paginaActual, orderBy]);

  useEffect(() => {
    if (searchTerm === "") {
      getLubricantesPaginated(paginaActual, orderBy, searchTerm).then((res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        // alert(JSON.stringify(res.data))
        setLubricantes(
          res.data.items.map((res) => {
            return {
              id: res.id,
              codigo: res.codigo_lub,
              descripcion: res.desc_lub,
              habilitado: res.status,
              tipo: res.tipo_lub_id.desc_tplub,
            };
          })
        );
      });
    } else {
      setPaginaActual(1);
      getLubricantesSearch("", searchTerm).then((res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        // alert(JSON.stringify(res.data))
        setLubricantes(
          res.data.items.map((res) => {
            return {
              id: res.id,
              codigo: res.codigo_lub,
              descripcion: res.desc_lub,
              habilitado: res.status,
              tipo: res.tipo_lub_id.desc_tplub,
            };
          })
        );
      });
    }
  }, [searchTerm]);

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setLubricantesId(valueRow.id);
      setModalStatus2(true);
      // handleDeleteLubricante(valueRow.id)
    }
  };
  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };
  const handleDeleteLubricante = (unidadId) => {
    setIsLoading(true);
    setSuccess(false);
    deleteLubricante(unidadId)
      .then(() => {
        // setKey(key + 1);
        setIsLoading(false);
        setMsjToast("Tipo de de Lubricante eliminado con exito");
        setLubricantesId("");
        let paginaActualTemp = paginaActual;
        if (lubricantes.length - 1 === 0 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        return getLubricantesPaginated(paginaActualTemp, orderBy, searchTerm);
      })
      .then((res) => {
        setIsLoading(false);
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setSuccess(true);
        setMsjToast("Lubricante eliminado con exito");
        setTimeout(() => {
          setSuccess(false);
          setLubricantes(
            res.data.items.map((res) => {
              return {
                id: res.id,
                codigo: res.codigo_lub,
                descripcion: res.desc_lub,
                habilitado: res.status,
                tipo: res.tipo_lub_id.desc_tplub,
              };
            })
          );
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
        setMsjToast("Error al eliminar El Lubricante");
        setError(true);
      });
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  return (
    <Styled>
      {isLoading &&
        <CircularProgress size={30}
          sx={{
            position: "fixed",
            bottom: "0",
            padding: "10px",
            zIndex: "9999"
          }}
        />
      }
      {success && (
        <Toast title={msjToast} close={() => setSuccess(false)} />
      )}
      {error && (
        <Toast
          type={"error"}
          title={msjToast}
          close={() => setError(false)}
        />
      )}
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            setSuccess(true);
            setMsjToast(msj);
          } else if (status === "error") {
            setError(true);
            setMsjToast(msj);
          }
          setModalStatus(false);
          getLubricantesPaginated(paginaActual, orderBy, searchTerm).then(
            (res) => {
              setPageCount(res.data.totalPages);
              setItemsCount(res.data.totalItems);
              setLubricantes(
                res.data.items.map((res) => {
                  return {
                    id: res.id,
                    codigo: res.codigo_lub,
                    descripcion: res.desc_lub,
                    habilitado: res.status,
                    tipo: res.tipo_lub_id.desc_tplub,
                  };
                })
              );
            }
          );
          setTimeout(() => {
            setSuccess(false);
            setError(false); 
            setMsjToast(''); 
          }, 1000)
        }}
        type={"lubricantes"}
      />
      <HeaderTable
        styledHead="cfg"
        cfg
        title="Lubricantes"
        valueBuscador={(value) => setSearchTerm(value)}
        labelButton={"Nuevo Lubricante"}
        onClickButton={() => {
          handleClickButtonHeader();
        }}
        value={searchTerm}
        search
        isClearable={() => { setSearchTerm("") }}
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={lubricantes}
        cRows={20}
        totalRows={itemsCount}
        pagination={true}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        actualpage={paginaActual}
        // valueBuscador={valueBuscador}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteLubricante(lubricanteId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default Lubricantes;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
    //height: 100vh;
  }
`;

import React, { useEffect, useState } from "react"
import RenderImg from "../../RenderImg/RenderImg.tsx";
import Limsoil from "../../../assets/Login/LimsOil.png";
import { IconButton } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { Styled, DivImg, DivLabel, Title, DivIconLogout } from "./UsuarioStyled";
import { useAuth } from "../../../context/AuthContext";
import { lookupUser } from "../../../api/auth.js";
import { useNavigate } from "react-router-dom";

const Usuario = () => {
  const Navigate = useNavigate();
  const [user, setUser] = useState({});
  const { logout, token } = useAuth();
  const tokenId = token ? token.id : "";
  // const rolUser = token?.rol === "SUPERADMIN" ? true : false;
  useEffect(() => {
    let isMounted = true;
    const fetchUser = async () => {
      try {
        const res = await lookupUser(tokenId);
        if (isMounted) {
          setUser(res?.data ?? {});
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
      }
    };
    fetchUser();
    return () => {
      isMounted = false;
    };
  }, [tokenId]);

  return (
    <Styled>
      <DivImg>
        {/* {rolUser && <RenderImg img={Limsoil} alt="imgLogin" className="img" /> } */}
        <RenderImg
          img={user.imageUrl ? `data:image/png;base64,${user.imageUrl}` : Limsoil}
          alt="imgLogin"
          className="img"
          width="100%"
          onClick={() => { Navigate("/micuenta") }} />
      </DivImg>
      <DivLabel>
        <Title>
          {`${user?.firstName} ${user?.lastName}`}
        </Title>
      </DivLabel>

      <DivIconLogout onClick={() => { logout() }}>
        <IconButton>
          <Logout />
        </IconButton>
      </DivIconLogout>
    </Styled>
  )
}

export default Usuario
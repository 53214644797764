import {
  EditOutlined,
  DeleteOutlineOutlined,
  RemoveRedEyeOutlined,
} from "@mui/icons-material";
import controlPanel from "../../assets/Icons/Buttons/Muestras/controlPanel.svg";
import excel from "../../assets/Icons/Buttons/Muestras/excel.svg";
import DiagMuestras from "../../assets/Icons/Buttons/Muestras/DiagMuestras.svg";
import Print from "../../assets/Icons/Buttons/Muestras/Print.svg";
import { RenderImgStyled, RenderImgStyle } from "../Tables/TablesStyled";

export const buttonsDefault = [
  {
    titleToolTip: "Ver",
    icon: <RemoveRedEyeOutlined sx={{ color: "#163C5B" }} />,
    className: "buttonVer"
  },
  {
    titleToolTip: "Editar",
    icon: <EditOutlined sx={{ color: "#163C5B" }} />,
    className: "buttonEditar"
  },
  {
    titleToolTip: "Eliminar",
    icon: <DeleteOutlineOutlined sx={{ color: "#163C5B" }} />,
    className: "buttonEliminar"
  },
]

export const buttonsDefaultver = [
  {
    titleToolTip: "Ver",
    icon: <RemoveRedEyeOutlined sx={{ color: "#163C5B" }} />,
    className: "buttonVer"
  }

]

export const buttonsMuestrasOrdenesTrabajo = [
  {
    titleToolTip: "ControlPanel",
    icon: <RenderImgStyled img={controlPanel}
      style={{ padding: "5px" }} />,
    className: "buttonMuestras"
  },
  {
    titleToolTip: "Editar",
    icon: <EditOutlined sx={{ color: "#163C5B" }} />,
    className: "buttonEditar"
  },
  {
    titleToolTip: "Excel",
    icon: <RenderImgStyled img={excel}
      style={{ padding: "5px" }} />,
    className: "buttonMuestras"
  },
  {
    titleToolTip: "Eliminar",
    icon: <DeleteOutlineOutlined sx={{ color: "#163C5B" }} />,
    className: "buttonEliminar"
  },

]
export const buttonsMuestras = [
  {
    titleToolTip: "Ver",
    icon: <RemoveRedEyeOutlined sx={{ color: "#163C5B" }} />,
    className: "buttonVer"
  },
  {
    titleToolTip: "Editar",
    icon: <EditOutlined sx={{ color: "#163C5B" }} />,
    className: "buttonEditar"
  },
  {
    titleToolTip: "Diagnóstico",
    icon: <RenderImgStyled img={DiagMuestras}
      style={{ padding: "5px" }} />,
    className: "buttonDiagnostico"
  },
  {
    titleToolTip: "Imprimir",
    icon: <RenderImgStyle img={Print}
    />,
    className: "buttonImprimir"
  },
  {
    titleToolTip: "Eliminar",
    icon: <DeleteOutlineOutlined sx={{ color: "#163C5B" }} />,
    className: "buttonEliminar"
  },

]

export const buttonsDashboardClientes = [
  {
    titleToolTip: "Ver",
    icon: <RemoveRedEyeOutlined sx={{ color: "#163C5B" }} />,
    className: "buttonVer"
  },
  {
    titleToolTip: "Imprimir",
    icon: <RenderImgStyle img={Print}
    />,
    className: "buttonImprimir"
  },
]
export const buttonsDashboardLaboratorio = [
  {
    titleToolTip: "ControlPanel",
    icon: <RenderImgStyled img={controlPanel}
      style={{ padding: "5px" }} />,
    className: "buttonMuestras"
  },
  {
    titleToolTip: "Editar",
    icon: <EditOutlined sx={{ color: "#163C5B" }} />,
    className: "buttonEditar"
  },
  {
    titleToolTip: "Eliminar",
    icon: <DeleteOutlineOutlined sx={{ color: "#163C5B" }} />,
    className: "buttonEliminar"
  },
]
export const buttonsInfoMuestras = [
  {
    titleToolTip: "Ver",
    icon: <RemoveRedEyeOutlined sx={{ color: "#163C5B" }} />,
    className: "buttonVer"
  },
  {
    titleToolTip: "Imprimir",
    icon: <RenderImgStyle img={Print}
    />,
    className: "buttonImprimir"
  },
]

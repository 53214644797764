import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Función para cargar env-config.js antes de renderizar la aplicación
function loadEnvConfig() {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = '/env-config.js';
    script.async = true;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

// Espera a que env-config.js cargue antes de inicializar la app
loadEnvConfig()
  .then(() => {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  })
  .catch((error) => {
    console.error("Error al cargar env-config.js:", error);
  });

reportWebVitals();

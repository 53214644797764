import React, { useReducer, useState, useEffect } from 'react';
import Input from '../../../../../Input/Input.jsx';
import CustomButton from '../../../../../Button/CustomButton.tsx';
import { StyledModalcomponentMuestras } from '../../../../../Modal/Styled.jsx';
import { createResultadoByMuestraId, getAnalisisOptions } from '../../../../../../api/Muestras.js';

const NuevoResultadoModal = (props) => {
  const [formCompleto, setFormCompleto] = useState(false);
  const [analisis, setAnalisis] = useState(null);
  const [selectedAnalisis, setSelectedAnalisis] = useState(null);
  const [resultado, setResultado] = useState("");
  
  /*const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    analisis: '',
    resultado: '',
  });*/

  useEffect(() => {
    fetchAnalisis(); // Busco todos los analisis y los dejo preparados para el modal
  },[])

  const fetchAnalisis = async () => {
    const res = await getAnalisisOptions();
    const unidades = res?.data?.map((item) => ({
      value: item.id,
      label: item.description
    }))
    setAnalisis(unidades);
  }

  /*useEffect(() => {
    const campoValido = campo => form[campo] !== '';
    const camposaValidar = ['analisis', 'resultado'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);*/

  /*const handleChange = (e) => {
    const { name, value } = e.target;
    const fieldValue = value;
    setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
  };*/

  const handleSubmit = (e) => {
      const body = { "valor_res" : resultado, "analisis_id": selectedAnalisis.value, "status": "Activo", "notificado_sn_res": "N"}
      //createResultadoByMuestraId(props.datos.datos, body)

      if (props.onClick) {
        props.onClick("Confirmar", body);
      }
  }

  const titulo = () => {
    const titulo = "Incorporar nuevo análisis"
    return titulo
  }
  const handleClickCancelar = () => {
    if (props.onClick) {
      props.onClick("Cancelar");
    }
  };

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={handleClickCancelar}
          label={"Cancelar"}
          variant="contained"
          className={`buttonColorDisabled`}
        />

        <CustomButton
          label={"Confirmar"}
          variant="contained"
          className={`buttonColorDefault btn`}
          type="submit"
          disabled={resultado === "" || selectedAnalisis === null}
        />

      </>
    );
  };
  return (
    <StyledModalcomponentMuestras sx={{
      width: "50%",
      padding: "40px",
      '@media (max-width: 768px)': {
        width: "90%",
      },
    }}>
      <div className="nuevoAnalisis">
        <div className="divLabel">
          <p className="titulo">{titulo()}</p>
        </div>
        <form className="formulario" onSubmit={handleSubmit}>
          <div className="divInputs">
            <Input
              reactSelect
              options={analisis}
              value={selectedAnalisis}
              onChange={(e) => setSelectedAnalisis(e)}
              name="unidad"
              placeholder="Seleccione el análisis"
              isDisabled={analisis === null}
            />
            <Input
              label={"Resultado"}
              placeholder={"resultado"}
              className="input"
              name="resultado"
              value={resultado}
              onChange={(e) => {setResultado(e.target.value)}}
            // className={res.className}
            // classnamediv={res.classNameDiv}
            />
            <div className="divButtonsItem">
              {renderButtons()}
            </div>
          </div>
        </form>
      </div>
    </StyledModalcomponentMuestras>
  )
}

export default NuevoResultadoModal
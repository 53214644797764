import React, { useState, useEffect } from 'react'
import RenderImg from '../RenderImg/RenderImg.tsx';
import { IconButton, Snackbar } from '@mui/material';
import { Close } from '@mui/icons-material';
import Error from "../../assets/Icons/Toast/Error.svg";
import Success from "../../assets/Icons/Toast/Success.svg";

const Toast = (props) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    if (props.close) {
      props.close(); 
    }
  };
  const getProps = () => {
    let type;
    const typeError = {
      background: "#D44B4B",
      title: props.title ? props.title : "nada",
      icon: Error,
      alertMsj: "Error"
    }
    const typeSucces = {
      background: "#50A449",
      title: props.title ? props.title : "nada",
      icon: Success,
      alertMsj: ""
    }
    if (props.type === "error") {
      type = typeError;
    } else {
      type = typeSucces;
    }
    return type;
  };

  const action = (
    <React.Fragment>

      <RenderImg img={getProps().icon} style={{ padding: "5px" }} />
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    const timer = setTimeout(handleClose, 100000);
    return () => clearTimeout(timer);
  }, []);
  return (
    open && (
      <Snackbar
        open={open}
        onClose={() => { props.close ? props.close() : handleClose() }}
        message={props.title}
        action={action}
        sx={{
          '& .MuiPaper-root': {
            background: getProps().background,
            position: 'fixed',
            bottom: `${100 + (props.index * 60)}px`,
            left: '0px', 
            zIndex: 1300 + props.index, 
          },
        }}
      />
    )
  )
}

export default Toast
// <AlertStyled
//   icon={<RenderImg img={getProps().icon} style={{ padding: "5px" }} />}
//   style={{ background: getProps().background, zIndex: 9999, position: 'fixed', top: '0px', right: '0px', width: '95%', left: '0px', margin: 'auto' }}
//   action={
//     <BoxActionStyled>
//       <TypographyTitle> {getProps().alertMsj}</TypographyTitle>
//       <IconButton
//         aria-label="close"
//         color="inherit"
//         size="medium"
//         onClick={() => { props.close ? props.close() : handleClose() }}
//         disableRipple
//       >
//         <Close fontSize="inherit" />
//       </IconButton>
//     </BoxActionStyled >
//   }
// >
//   <TypographyTitle>{getProps().title}</TypographyTitle>
// </AlertStyled >
import React, { useEffect, useState } from 'react';
import {
  DashBoardLeft,
  BoxHeaderLeft,
  TitleLeft,
  DescriptionLeft,
} from '../DashboardLaboratorioStyled';
import { getMuestraConteo } from "../../../../../api/Inicio";
import BarChartComponent from '../../../../../components/BarChart/BarChartComponent';

const footerLabel = [
  'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
  'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
];

const LeftDashboardLaboratorio = () => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchMuestrasConteo = async () => {
      try {
        const res = await getMuestraConteo();
        const dataProcesada = Array(12).fill(0);
        const dataPublicada = Array(12).fill(0);
        res.data.forEach(item => {
          const [year, month] = item.yearMonth.split('-');
          const monthIndex = parseInt(month, 10) - 1;
          dataProcesada[monthIndex] = item.contadorProcesada;
          dataPublicada[monthIndex] = item.contadorPublicada;
        });

        
        setChartData([
          {
            data: dataProcesada,
            color: '#fa0404',
            label: "Pendiente"
          },
          {
            data: dataPublicada,
            color: '#20e616',
            label: "Publicada"
          }
        ]);

      } catch (error) {
        console.error(error);
      }
    };
    fetchMuestrasConteo();
  }, []);

  return (
    <DashBoardLeft>
      <BoxHeaderLeft>
        <TitleLeft>Cantidad Total de Muestras Ingresadas en el Año en Curso</TitleLeft>
        <DescriptionLeft>Aquí podrá consultar los totales mensuales de muestras de acuerdo al estado de diagnóstico
          para consultar otros gráficos presione la opción “Reportes” que se encuentra en la cabecera</DescriptionLeft>
      </BoxHeaderLeft>

      <BarChartComponent
        series={chartData}
        width={1000}
        height={500}
        grid={{ horizontal: true }}
        footerLabel={footerLabel}
      />
    </DashBoardLeft>
  );
}

export default LeftDashboardLaboratorio;

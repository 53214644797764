import axios from "axios";

// Función para obtener el valor de `REACT_APP_BASEURL` después de cargar `env-config.js`
function getBaseURL() {
  return window._env_?.REACT_APP_BASEURL || "http://localhost:9000";
}

export const tokenAxios = (valueToken) => {
  return valueToken ? valueToken : "";
};

// Crear la instancia de axios sin establecer `baseURL` inicialmente
const instance = axios.create({
  withCredentials: false,
});

// Interceptor para configurar `baseURL` dinámicamente en cada solicitud
instance.interceptors.request.use(
  config => {
    config.baseURL = getBaseURL();  // Asigna `baseURL` en cada solicitud
    const token = tokenAxios(localStorage.getItem('token'));
    if (token) {
      config.headers.Authorization = `basic ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;

import { Box, styled, Typography } from "@mui/material";
import { fonts, colors } from "../../../../assets/styles/theme.ts";

export const Styled = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  height: 100%;
`;

export const StyledTypography = styled(Typography)`
  font-family: ${fonts.primary};
  color: ${colors.blueDark};
  font-weight: 600;
  font-size: 20px ;
`;
export const BoxHeader = styled(Box)`
  display:flex;
  justify-Content:space-between;
  align-items: center;
  width:100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;
export const BoxInputs = styled(Box)`
  display:flex;
  justify-Content:space-between;
  align-items: center;
  gap: 10px;
  width:70%;
  .divInput{
    width:100%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .divInput{
      width: 100%;
    }
  }
`;
export const BoxSubHeader = styled(Box)`
  display:flex;
  justify-Content:space-between;
  .MuiBox-root{
    display: flex;
    gap: 5px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    .MuiBox-root:nth-of-type(1) {
      flex-direction: column;
    }
  }
`;




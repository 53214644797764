import React, { useState } from 'react';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const CheckboxRes = (props) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    const isChecked = event.target.checked;
    const value = event.target.value;
    const label = props.label || '';
    const name = event.target.name;

    setChecked(isChecked);

    if (props.onChange) {
      props.onChange({
        checked: isChecked,
        value: isChecked ? value : '',
        label: isChecked ? label : '',  
        name: name  
      });
    }
  };


  return (
    <Styled>
      <FormControlLabel
        control={
          <Checkbox
            value={props.value ? props.value : ''}
            checked={checked}
            onChange={handleChange}
            disableRipple
            size="small"
            name={props.name ? props.name: ''}
          />
        }
        label={props.label ? props.label : ''}
        className="formControl"
      />

    </Styled>
  )
}

export default CheckboxRes;

const Styled = styled.div`
.formControl{
  span{
    font-family: Inter;
    font-weight: 500;
  }
}
`
import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import styled from "styled-components";
import Toast from "../../../../components/Toast/Toast";
import {
  deleteEstadosFinales,
  getEstadosFinalesPaginated,
} from "../../../../api/Configuracion";
import Modal2 from "../../../../components/Modal/Modal";
import { CircularProgress, Chip } from "@mui/material";
import { colorClear } from "../../../../components/ClearColor/ColorClear"
const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "abreviatura", label: "Abreviatura" },
  { id: "orden", label: "Orden" },
  { id: "color", label: "Color" },
  { id: "imagen", label: "Imagen" },
  { id: "buttons" },
];

const EstadosFinales = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [estadosFinales, setEstadosFinales] = useState([]);
  const [estadosFinalesId, setEstadosFinalesId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    fetchEstadosFinalesPaginated(paginaActual, orderBy, searchTerm, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginaActual, orderBy]); // Ignorando fetchEstadosFinalesPaginated y searchTerm en la dependencia array

  const fetchEstadosFinalesPaginated = async (
    pagina,
    order,
    search,
    action
  ) => {
    const res = await getEstadosFinalesPaginated(pagina, order, search);

    // console.log("res:", res); //////////--------ACAAAAAAA--------//////////
    setPageCount(res.data.totalPages);
    setItemsCount(res.data.totalItems);

    if (action === "new") {
      if (res.data.items.length + 1 === 21) {
        setPaginaActual(res.data.totalPages);
      }
    } else if (action === "delete") {
      setKey(key + 1);
      setMsjToast("Estado final eliminado con exito.");
      setSuccess(true);
    } else {
      if (res.data.totalPages === 0) {
        setPaginaActual(1);
      } else if (res.data.totalPages < paginaActual) {
        setPaginaActual(res.data.totalPages);
      } else {
        setPaginaActual(paginaActual);
      }
    }
    setEstadosFinales(
      res.data.items.map((res) => {
        let imagen;
        if (res.imagen_efm === null || res.imagen_efm === "") {
          imagen = "";
        } else {
          imagen = `${res.imagen_efm_content_type},${res.imagen_efm}`;
        }

        return {
          id: res.id,
          codigo: res.codigo_efm,
          descripcion: res.desc_efm,
          abreviatura: res.abrev_efm,
          orden: res.orden_efm,
          color: <Chip
            label={res.color_efm || "N/A"}
            color={res?.color_ef_ot ? "default" : undefined}
            sx={{
              backgroundColor: res.color_efm ?? "",
              color: colorClear(res.color_efm ?? "#FFFFFF") ? "white" : "black",
              minWidth: "100px",
            }}
          />,
          habilitado: res.status,
          imagen: imagen,
          codigo_template_email: res.codigo_template_email_efm,
        };
      })
    );
  };

  const handleClickButonTable = (value, valueRow) => {
    const colorLabel = valueRow?.color?.props?.label ?? "";
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({
        button: value.toLowerCase(), datos: {
          ...valueRow, ...(value !== "Crear" && {
            color: colorLabel,
          }),
        }
      });
      // console.log(valueRow)
    } else {

      setEstadosFinalesId(valueRow.id);
      setModalStatus2(true);
      // handleDelete(valueRow.id)
    }
  };

  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };

  const handleDelete = (id) => {
    setIsLoading(true);
    // setSuccess(false);
    setModalStatus2(false);
    deleteEstadosFinales(id)
      .then(() => {
        setIsLoading(false);
        setEstadosFinalesId("");
        let paginaActualTemp = paginaActual;
        if (estadosFinales.length === 1 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        return fetchEstadosFinalesPaginated(
          paginaActual,
          orderBy,
          searchTerm,
          "delete"
        );
      })
      .catch((err) => {
        setIsLoading(false);
        setMsjToast("Error al eliminar el estado final.");
        setError(true);
      });
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  const handleBuscador = (value) => {
    setSearchTerm(value);
    fetchEstadosFinalesPaginated(paginaActual, orderBy, value, "search");
  };

  return (
    <Styled>
      {isLoading && (
        <CircularProgress
          size={30}
          sx={{
            position: "fixed",
            bottom: "0",
            padding: "10px",
            zIndex: "9999",
          }}
        />
      )}
      {success && (
        <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
      )}
      {error && (
        <Toast
          key={key}
          type={"error"}
          title={msjToast}
          close={() => setError(false)}
        />
      )}

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={"estadosFinales"}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          // console.log("Msj: ", msj);
          // console.log("Status: ", status);
          if (status === "success") {
            setSuccess(true);
            setMsjToast(msj);
            fetchEstadosFinalesPaginated(
              paginaActual,
              orderBy,
              searchTerm,
              msj.includes("cread") ? "new" : ""
            );
          } else if (status === "error") {
            setError(true);
            setMsjToast(msj);
          } else {
            fetchEstadosFinalesPaginated(
              paginaActual,
              orderBy,
              searchTerm,
              "ver"
            );
          }
          setTimeout(() => {
            setSuccess(false);
            setError(false);
            setMsjToast("");
          }, 1000);
          setModalStatus(false);
        }}
      />

      <HeaderTable
        styledHead="cfg"
        cfg
        title="Estados Finales"
        value={searchTerm}
        valueBuscador={(value) => handleBuscador(value)}
        isClearable={() => {
          handleBuscador("");
        }}
        labelButton={"Crear nuevo estado final"}
        onClickButton={() => {
          handleClickButtonHeader();
        }}
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={estadosFinales}
        cRows={20}
        totalRows={itemsCount}
        actualpage={paginaActual}
        pagination={true}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        // valueBuscador={valueBuscador}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      />

      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDelete(estadosFinalesId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default EstadosFinales;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
    //height: 100vh;
  }
`;

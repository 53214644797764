import { Box, Typography, styled } from "@mui/material";
import { colors, fonts } from "../../assets/styles/theme.ts";


export const Styled = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  width: 100%;
`;

export const BoxMiCuenta = styled(Box)`
  width: 768px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 8px;
  border: 2px solid ${colors.turquoise}; 
  margin:auto;
  overflow-y: auto;
  @media (max-width: 768px) {
    width: 90%
  }
`;
export const Header = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 20px;
  padding: 20px 0px 0px 0px;
`;
export const BoxImg = styled(Box)`
  width: 120px; 
  height: 120px;  
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Title = styled(Typography)`
  font-family: ${fonts.primary} ;
  color: ${colors.blueDark};
`;
export const Body = styled(Box)`
  width: 100%;
  background: ${colors.smokeWhite};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
export const BoxDataUsuario = styled(Box)`
  display:flex;
  padding: 10px 0px;
  flex-direction: column;
`;
export const Description = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Roboto ;
  color: ${colors.steelBlue};
  align-items: center;
  font-size: 14px;
  font-weight: 400;
`;




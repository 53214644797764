import React, { useState, useEffect } from "react";
import { Styled, StyledTypography } from "./EstadosOrdenesDeTrabajoStyled.js";
import { Search } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";
import { RenderImgStyled } from "../../../Task/Items/SuperAdminStyled.js";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable.js";
import {
  getEstadoOrdenesTrabajoPaginated,
  deleteEstadoOrdenesTrabajo,
} from "../../../../api/Configuracion.js";
import More from "../../../../assets/Icons/Buttons/More.svg";
import CustomButton from "../../../../components/Button/CustomButton.tsx";
import Input from "../../../../components/Input/Input.jsx";
import Tables from "../../../../components/Tables/Tables.jsx";
import Modal from "../../../../components/Modal/Modal.jsx";
import Modal2 from "../../../../components/Modal/Modal.jsx";
import Toast from "../../../../components/Toast/Toast.jsx";
import { CircularProgress } from "@mui/material";
import { colorClear } from "../../../../components/ClearColor/ColorClear.js";
const columnsDefaultCfg = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "accion", label: "Accion" },
  { id: "orden", label: "Orden" },
  { id: "final", label: "¿Final?" },
  { id: "cancelable", label: "¿Cancelable?" },
  { id: "cancelar", label: "¿Cancelar?" },
  { id: "estadoSiguiente", label: "Estado siguiente" },
  { id: "codigoTemplateEmail", label: "Codigo Template Email" },
  { id: "buttons" },
];

const EstadosOrdenesDeTrabajo = () => {
  const [datosModal, setDatosModal] = useState("");
  const [statusModal, setModalStatus] = useState(false);
  const [statusModal2, setModalStatus2] = useState(false);
  const [estadoOtrabajo, setEstadOtrabajo] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [paginaActual, setPaginaActual] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [estadoOtrabajoId, setEstadoOtrabajoId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    fetchEstadoOrdenesTrabajoPaginated(
      paginaActual,
      orderBy,
      searchTerm,
      "primera"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginaActual, orderBy]); // Ignorando fetchEstadoOrdenesTrabajoPaginated y searchTerm en la dependencia array

  const handleClickButtonTable = (value, valueRow) => {
    const descripcionLabel = valueRow?.descripcion?.props?.label ?? "";
    const estadoSiguienteLabel = valueRow?.estadoSiguiente?.props?.label ?? "";

    if (value !== "Eliminar") {
      setDatosModal({
        button: value.toLowerCase(),
        datos: {
          ...valueRow,
          ...(value !== "Crear" && {
            descripcion: descripcionLabel,
            estadoSiguiente: estadoSiguienteLabel,
          }),
        },
      });
      setModalStatus(true);
    } else {
      setDatosModal("");
      setEstadoOtrabajoId(valueRow.id);
      setModalStatus2(true);
    }
    if (value === "Crear") {
      setDatosModal("");
      setModalStatus(true);
    }
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }
    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  const handleDeleteEstadoOtrabajo = (estadosOtrabajoId) => {
    setIsLoading(true);
    setSuccess(false);
    deleteEstadoOrdenesTrabajo(estadosOtrabajoId)
      .then(() => {
        setIsLoading(false);
        setEstadoOtrabajoId("");
        let paginaActualTemp = paginaActual;
        if (estadoOtrabajo.length === 1 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        return fetchEstadoOrdenesTrabajoPaginated(
          paginaActualTemp,
          "denominacion",
          searchTerm,
          "delete"
        );
      })
      .catch((err) => {
        setIsLoading(false);
        setMsjToast("Error al eliminar el estado de trabajo.");
        setError(true);
      });
  };

  const fetchEstadoOrdenesTrabajoPaginated = async (
    pagina,
    order,
    search,
    action
  ) => {
    const res = await getEstadoOrdenesTrabajoPaginated(pagina, order, search);

    setPageCount(res.data.totalPages);
    setItemsCount(res.data.totalItems);

    if (action === "new") {
      if (res.data.items.length + 1 === 21) {
        setPaginaActual(res.data.totalPages);
      }
    } else if (action === "delete") {
      setKey(key + 1);
      setMsjToast("Tipo de Evento eliminado con exito.");
      setSuccess(true);
    } else {
      if (res.data.totalPages === 0) {
        setPaginaActual(1);
      } else if (res.data.totalPages < paginaActual) {
        setPaginaActual(res.data.totalPages);
      } else {
        setPaginaActual(paginaActual);
      }
    }

    setEstadOtrabajo(
      res.data.items.map((res) => {
        return {
          id: res?.id ?? "",
          codigo: res?.codigo_ef_ot ?? "",
          descripcion: (
            <Chip
              label={res?.desc_ef_ot || "N/A"}
              color={res?.color_ef_ot ? "default" : undefined}
              sx={{
                backgroundColor: res?.color_ef_ot ?? "",
                color: colorClear(res?.color_ef_ot ?? "#FFFFFF") ? "white" : "black",
                minWidth:"150px",
                maxWidth:"150px"
              }}
            />
          ),
          accion: res?.accion_ef_ot ?? "",
          orden: res?.orden_ef_ot ?? "",
          final: res?.final_sn_ef_ot ?? "",
          cancelable: res?.cancelable_sn_ef_ot ?? "",
          cancelar: res?.cancelar_sn_ef_ot ?? "",
          estadoSiguiente: (
            <Chip
              label={res?.estado_siguiente?.desc_ef_ot || "N/A"}
              color={res?.estado_siguiente.color_ef_ot ? "default" : undefined}
              sx={{
                backgroundColor: res?.estado_siguiente.color_ef_ot ?? "",
                color: colorClear(res?.estado_siguiente.color_ef_ot  ?? "#FFFFFF") ? "white" : "black",
                minWidth:"100px",
              }}
            />
          ),
          codigoTemplateEmail:
            res?.codigo_template_email_ef_ot?.description ?? "",
          templateEmailId: res?.codigo_template_email_ef_ot?.id ?? 0,
          estadoSiguienteId: res.estado_siguiente.id ?? 0,
          color: res?.color_ef_ot ?? "",
        };
      })
    );
  };

  const handleBuscador = (value) => {
    setSearchTerm(value);
    fetchEstadoOrdenesTrabajoPaginated(paginaActual, orderBy, value, "search");
  };

  return (
    <Styled>
      {isLoading && (
        <CircularProgress
          size={30}
          sx={{
            position: "fixed",
            bottom: "0",
            padding: "10px",
            zIndex: "9999",
          }}
        />
      )}
      {success && (
        <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
      )}
      {error && (
        <Toast
          key={key}
          type={"error"}
          title={msjToast}
          close={() => setError(false)}
        />
      )}

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={"estadoOrdenesDeTrabajo"}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            setKey(key + 1);
            setSuccess(true);
            setMsjToast(msj);
            fetchEstadoOrdenesTrabajoPaginated(
              paginaActual,
              orderBy,
              searchTerm,
              msj.includes("cread") ? "new" : ""
            );
          } else if (status === "error") {
            // setKey(key+1);
            setError(true);
            setMsjToast(msj);
          } else if (status === "cancel") {
            fetchEstadoOrdenesTrabajoPaginated(
              paginaActual,
              orderBy,
              searchTerm,
              msj.includes("cread") ? "new" : ""
            );
          }
          setModalStatus(false);
        }}
      />
      <StyledTypography>Estados Ordenes de Trabajo</StyledTypography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 0px",
        }}
      >
        <Box sx={{ width: "30%" }}>
          <Input
            inputProp={true}
            positionIcon={"start"}
            placeholder={"Buscar por código, descripción, etc"}
            icon={<Search />}
            size={"small"}
            onChange={(e) => handleBuscador(e.target.value)}
            search
            value={searchTerm}
            isClearable={() => {
              handleBuscador("");
            }}
            className="inputComponent"
            style={{ borderRadius: "10px", paddingLeft: "0px" }}
          />
        </Box>
        <CustomButton
          label={"Nuevo estado de orden de trabajo"}
          startIcon={<RenderImgStyled img={More} />}
          variant={"contained"}
          className="buttonHeaderTable"
          onClick={() => {
            handleClickButtonTable("crear");
          }}
        />
      </Box>
      <Tables
        typeTable={"prop"}
        columns={columnsDefaultCfg}
        rows={estadoOtrabajo}
        cRows={10}
        totalRows={itemsCount}
        actualpage={paginaActual}
        pagination={true}
        tableColor={false}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        buttons={buttonsDefault}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButtonTable(value, valuesRow);
        }}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteEstadoOtrabajo(estadoOtrabajoId);
        }}
      />
    </Styled>
  );
};

export default EstadosOrdenesDeTrabajo;

import React, { useReducer, useState, useEffect } from 'react';
import Input from '../../../../Input/Input.jsx';
import CustomButton from '../../../../Button/CustomButton.tsx';

import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getTipoLubricantes, createTipoLubricante, updateTipoLubricante } from '../../../../../api/Configuracion';
import Toast from '../../../../Toast/Toast.jsx';
import Preloader from '../../../../Preloader/Preloader.jsx';

import { StyledModalcomponent } from '../../../Styled.jsx';

const TipoLubricantes = (props) => {
  const propsTipoLubricantes = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos }
  }
  const { ver, editar, datos } = propsTipoLubricantes(props.datos);

  const [formCompleto, setFormCompleto] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    id: datos.id ?? '',
    codigo: datos.codigo ?? '',
    descripcion: datos.descripcion ?? '',
    habilitado: datos.habilitado === "SI" || datos.habilitado === "ACTIVE"  ? true : false
  });

  useEffect(() => {
    const campoValido = campo => form[campo] !== '' && (campo !== 'habilitado' || form[campo]);

    const camposaValidar = ['codigo', 'descripcion', 'habilitado'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);


  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (ver) {
      return
    }
    const fieldValue = name === 'habilitado' ? checked : value;
    setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
  };
  const titulo = () => {
    const titulo = ver ? "Ver tipo de lubricantes" : editar ? "Editar tipo de lubricantes" : "Crear tipo de lubricantes";
    return titulo
  }
  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!editar ? !formCompleto : false}
          />
        )}
      </>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formattedData = {      
      "id": form.id,
      "codigo_tplub": form.codigo.toString(),
      "desc_tplub":form.descripcion,
      "abrev_tplub": "no lleva",
      "created_by": "cabs",
      "created_date": new Date(),     
      "status": form.habilitado?"SI":"NO",
      "tenantId":1,
      "eliminado": false    
  }
  
  if(editar){
    updateTipoLubricante(formattedData).then(res => {
      setIsLoading(false);       
      props.onClick({msj:"Tipo de Lubricante modificado con éxito", status:"success"});        
     
    }).catch((err)=>{
      setIsLoading(false);       
      props.onClick({msj:"No se Pudo editar el Tipo de Lubricante", status:"error"});
    });
  }else{ 
    createTipoLubricante(formattedData).then(res => {     
      setIsLoading(false);
      props.onClick({msj:"Tipo de Lubricante Creado con exito", status:"success"});   
    
    }).catch((err)=>{
      setIsLoading(false);       
      props.onClick({msj:"No se Pudo crear el tipo de Lubricante", status:"error"});
    });
  }
  }
  return (
    <StyledModalcomponent>
     <Preloader title="Cargando..." subtitle={"Espere unos Segundos por favor..."} loading={isLoading} />
    
    <div classname="toast" >      {success? <Toast title={msjToast} close={() => setSuccess(false)}/> : null}
      {error? <Toast type={"error"} title={"Error al " + (editar ? "modificar" : "crear") + "la unidad ?"} close={() => setError(false)}/> : null}
    </div> 
      <div className="tipoLubricantes">
        <div className="divLabel">
          <p className="titulo"> {titulo()} </p>
        </div>
        <div className="divInputsItem">
          <form className="formulario" onSubmit={handleSubmit}>
            <div className="divPrimerInput">
              <Input
                value={form.codigo}
                label="Código"
                className="inputCodigo input"
                variant="outlined"
                size={"medium"}
                name="codigo"
                onChange={handleChange}
                disabled={ver}
              />
              <FormControlLabel
                control={<Switch name="habilitado" disabled={ver} checked={form.habilitado} onChange={handleChange} />}
                label="Habilitar"
              />
            </div>

            <Input
              value={form.descripcion}
              label="Descripción"
              className="inputDesc input"
              variant="outlined"
              size={"medium"}
              name="descripcion"
              onChange={handleChange}
              disabled={ver}
            />
            <div className="divButtonsItem">
              {renderButtons()}
            </div>
          </form>
        </div>
      </div>
    </StyledModalcomponent>
  )
}

export default TipoLubricantes
import React, { useEffect, useReducer, useState } from 'react';
import Card from './Card.jsx';
import CardTwo from './CardTwo.jsx';
import Tables from '../../../../../Tables/Tables.jsx';
import { StyledModalcomponentMuestras } from '../../../../Styled.jsx';
import Checkboxx from '../../../../../Checkbox/Checkboxx.jsx';
import CustomButton from '../../../../../Button/CustomButton.tsx';
import Input from '../../../../../Input/Input.jsx';
import DropZone from '../../../../../DropZone/DropZone.jsx';
import AddIcon from '@mui/icons-material/Add';
import TaskIcon from '@mui/icons-material/Task';
import SendIcon from '@mui/icons-material/Send';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Modal from "../../../../../Modal/Modal.jsx";
import RenderImg from '../../../../../RenderImg/RenderImg.tsx';
import Preview from "../../../../../../assets/Normal.svg"
import { getLastIntervencionByEquipo, lookupDiagnostico } from '../../../../../../api/Clientes.js';
import Preloader from '../../../../../Preloader/Preloader.jsx';
import dayjs from 'dayjs';
import { createResultadoByMuestraId, getAllLubricante, getAnalisisOptions, updateDiagnosticoByMuestraId, updateResultadoByMuestraId } from '../../../../../../api/Muestras.js';
import CheckboxRes from './CheckboxRes.jsx';
import { Checkbox } from '@mui/material';
import { getCausasDeFallaOptions, getEstadosFinalesOptions, lookupMensajePredefinidoByEstadoFinal } from '../../../../../../api/Configuracion.js';
import { colors } from '../../../../../../assets/styles/theme.ts';

const columns = [
  { id: "resaltar", label: "Resaltar" },
  { id: "analisis", label: "Análisis" },
  { id: "resultado", label: "Resultado" },
  { id: "unidad", label: "Unidad" }
]

const rows = [{
  resaltar: <Checkboxx />,
  analisis: "Contenido de Agua y Sedimentos",
  resultado: <div className="divRowsMuestra" />,
  unidad: "Miligramo KOH por gramo",

}]

const buttonHeader = [
  {
    label: "Crear nuevo resultado",
    startIcon: <AddIcon />,
    classNameDiv: "divBtnResultado",
    className: "buttonHeaderTable btnResultado",
    size: "small"
  },
  {
    label: "Guardar",
    startIcon: <TaskIcon />,
    classNameDiv: "divBtn",
    size: "small",
    className: "buttonColorTable"
  },
  {
    label: "Guardar y publicar",
    startIcon: <SendIcon />,
    classNameDiv: "divBtn",
    size: "small",
    className: "buttonColorTable"
  },
  {
    label: "Imprimir",
    startIcon: <LocalPrintshopIcon />,
    classNameDiv: "divBtn",
    size: "small",
    className: "buttonColorTable"
  }
]

const inputs = {
  inputsA: [
    {
      label: "Fecha de Análisis",
      className: "input",
      classNameDiv: "divInputAnalisis",
      calendar: true,
    },
    {
      placeholder: "Lubricante Actual del Punto de Toma",
      reactSelect: true,
      className: "input",
      classNameDiv: "divInputPuntoDeToma",
    },
    {
      label: "Horas Uso Equipo",
      className: "input",
      classNameDiv: "divInputEquipo",
    },
    {
      label: "Horas Uso Lubricante",
      className: "input",
      classNameDiv: "divInputLubricante",
    },
    {
      label: "Última intervención",
      className: "input",
      classNameDiv: "divInputIntervencion",
    },
  ],
  inputsB: [
    {
      placeholder: "Estado  Final",
      reactSelect: true,
      className: "input",
      classNameDiv: "divInputEstado",
    },
    {
      placeholder: "Causa de Falla",
      reactSelect: true,
      className: "input",
      classNameDiv: "divInputEstado",
    },
  ],
  inputsC: [
    {
      placeholder: "Mensaje Predefinido",
      reactSelect: true,
      className: "input",
      classNameDiv: "divInputMensaje",
    },
    {
      label: "Diagnóstico",
      className: "input",
      classNameDiv: "divInputMensaje",
      multiline: true,
      minRows: 3,
      maxRows: 3,
    },
    {
      label: "Sugerencias",
      className: "input",
      classNameDiv: "divInputMensaje",
      multiline: true,
      minRows: 3,
      maxRows: 3
    },
    {
      type: "dropZone"
    },
    {
      type: "dropZone"
    }
  ]
}

const renderInputs = () => {
  const renderA = inputs?.inputsA?.map((res, i) => (
    <>
      {res.calendar ? (
        <LocalizationProvider key={i} dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']} />
          <DatePicker
            className="inputDate"
            label="Fecha Toma de Muestra"
            placeholder="Fecha Toma de Muestra"
            renderInput={(params) => (
              <Input
                {...params}
                className="input inputDate"
                variant="outlined"
                size="medium"
              />
            )}
          // onChange={(e) => { handleChangeCalendar(e, "fecha") }}
          />
        </LocalizationProvider>
      ) : (
        <Input
          key={i}
          label={res.label}
          size={res.size}
          placeholder={res.placeholder}
          inputSelect={res.inputSelect}
          className={res.className}
          classnamediv={res.classNameDiv}
          reactSelect={res.reactSelect ?? false}
        />
      )}
    </>
  ));

  const renderB = inputs?.inputsB?.map((res, i) => (
    <Input
      key={i}
      label={res.label}
      placeholder={res.placeholder}
      inputSelect={res.inputSelect}
      size={res.size}
      className={res.className}
      classnamediv={res.classNameDiv}
      reactSelect={res.reactSelect ?? false}
    />
  ));

  const renderC = inputs?.inputsC?.map((res, i) => (
    res.type !== "dropZone" ? (
      <Input
        key={i}
        label={res.label}
        placeholder={res.placeholder}
        inputSelect={res.inputSelect}
        size={res.size}
        type={res.type}
        multiline={res.multiline}
        className={res.className}
        classnamediv={res.classNameDiv}
        minRows={res.minRows}
        maxRows={res.maxRows}
        reactSelect={res.reactSelect ?? false}
      />
    ) : (
      <DropZone key={i} />
    )
  ));

  return { renderA, renderB, renderC };
};

const DiagMuestrasModal = (props) => {
  const propsDiagMuestras = (datosUnidades) => {
    const datos = datosUnidades?.datos ?? [];
    return { datos };
  };
  const { datos } = propsDiagMuestras(props.datos);
  const [typeModal, setTypeModal] = useState("");
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [diagnostico, setDiagnostico] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [resModal, setResModal] = useState(null);

  // Datos editables //
  const [fecha, setFecha] = useState(null);
  const [selectedLubricante, setSelectedLubricante] = useState(null);
  const [horasUsoEquipo, setHorasUsoEquipo] = useState(null);
  const [horasUsoLubricante, setHorasUsoLubricante] = useState(null);
  const [resultados, setResultados] = useState(null);
  const [selectedEstadoFinal, setSelectedEstadoFinal] = useState(null);
  const [selectedCausaFalla, setSelectedCausaFalla] = useState(null);
  const [selectedMensajePredefinido, setSelectedMensajePredefinido] = useState(null);
  const [msjDiagnostico, setMsjDiagnostico] = useState(null);
  const [msjSugerencias, setMsjSugerencias] = useState(null);
  const [lastIntervencion, setLastIntervencion] = useState(null);
  const [imagenes, setImagenes] = useState(null);

  // Options //
  const [lubricantes, setLubricantes] = useState(null);
  const [estadosFinales, setEstadosFinales] = useState(null);
  const [causasFalla, setCausasFalla] = useState(null);
  const [mensajesPredefinidos, setMensajesPredefinidos] = useState([]);

  useEffect(() => {
    fetchDiagnostico(datos.id) // Busco por id de Muestra
  }, [])

  useEffect(() => {
    const handleResultados = async () => {
      if(resModal?.msj === "Cancelar" || resModal === null) {
        return;
      } else {
        setIsLoading(true);
        try {
          await createResultadoByMuestraId(datos.id, resModal.resultado);
          await fetchDiagnostico(datos.id);
        } catch (error) {
          console.error("Error:", error)
        } finally {
          setIsLoading(false);
        }
      }
    };

    handleResultados();
  }, [resModal]);

  const fetchDiagnostico = async (idMuestra) => {
    lookupDiagnostico(idMuestra).then((res) => {
      setDiagnostico(res.data);
      setFecha(dayjs(res.data.fecha_toma_mue));
      setHorasUsoEquipo(res.data.horas_equipo_mue);
      setHorasUsoLubricante(res.data.horas_lubricante_mue);
      setResultados(res.data.resultado);
      setMsjDiagnostico(res.data.diagnostico_mue);
      setMsjSugerencias(res.data.sugerencias_mue);

      fetchLubricantes(res.data.lubricante.id); // Busco todos los lubricantes y seteo el actual
      fetchEstadosFinales(res.data.estado_final.id); // Busco todos los estados finales y seteo el actual
      fetchCausasDeFalla(res.data.causa_falla?.id); // Busco todas las causas de falla y seteo el actual
      fetchLastIntervencion(res.data.equipo.id); // Busco la ultima intervencion del equipo
    })
  }

  const fetchLastIntervencion = async (idEquipoActual) => {
    const res = await getLastIntervencionByEquipo(idEquipoActual);
    setLastIntervencion(res.data);
  }

  const fetchCausasDeFalla = async (idCausaDeFallaActual) => {
    const res = await getCausasDeFallaOptions()
    const unidades = res?.data?.map((item) => ({
      value: item.id,
      label: item.desc_falla
    }))
    setCausasFalla(unidades);

    const matchOption = unidades.find(
      (option) => option.value === idCausaDeFallaActual
    )
    setSelectedCausaFalla(matchOption);
  }

  const fetchLubricantes = async (idLubricanteActual) => {
    const res = await getAllLubricante();
    const unidades = res?.data?.map((item) => ({
      value: item.id,
      label: item.desc_lub,
    }));
    setLubricantes(unidades);

    const matchOption = unidades.find(
      (option) => option.value === idLubricanteActual
    )
    setSelectedLubricante(matchOption);
    setIsLoading(false);
  };

  const fetchEstadosFinales = async (idEstadoFinalActual) => {
    const res = await getEstadosFinalesOptions();
    const unidades = res?.data?.map((item) => ({
      value: item.id,
      label: item.desc_efm,
      image: item.imagen_efm,
      color: item.color_efm
    }));
    setEstadosFinales(unidades);
    
    const matchOption = unidades.find(
      (option) => option.value === idEstadoFinalActual
    )
    setSelectedEstadoFinal(matchOption);

    if(matchOption) fetchMensajesPredefinidos(idEstadoFinalActual)
  };

  const fetchMensajesPredefinidos = async (idEstadoFinalActual) => {
    const res = await lookupMensajePredefinidoByEstadoFinal(idEstadoFinalActual);
    const unidades = res?.data?.map((item) => ({
      value: item.id,
      label: item.nombre_m_pre,
      sugerencia: item.sugerencia_m_pre,
      diagnostico: item.diagnostico_m_pre
    }));
    setMensajesPredefinidos(unidades);
  }

  const handleChangeCheckbox = (e, resultado) => {
    console.log("Checkbox", resultado)

    setResultados(prevResultados =>
      prevResultados.map(resultadoActual => 
        resultadoActual.id === resultado.id 
          ? { ...resultadoActual, notificado_sn_res: resultadoActual.notificado_sn_res === 'N' ? 'S' : 'N' }
          : resultadoActual
      )
    );
  }

  const handleChangeResultado = (valor, resultado) => {
    console.log("Resultado", valor, resultado)

    setResultados(prevResultados =>
      prevResultados.map(resultadoActual => 
        resultadoActual.id === resultado.id 
          ? { ...resultadoActual, valor_res: valor }
          : resultadoActual
      )
    );
  }

  const handleButtonEstado = (estado) => {
    console.log("Estado", estado)
    setSelectedMensajePredefinido(null)
    setSelectedEstadoFinal(estado)
    fetchMensajesPredefinidos(estado.value)
  }
















  ///Buttons Header derecha
  const handleClickButtonHeader = (index, label) => {

    if (label?.label === "Crear nuevo resultado") {
      setModalStatus(!statusModal);
      setTypeModal("nuevoResultadoDiagMuestras")
      setDatosModal({ /*button: value.toLowerCase(), */datos: datos.id });
    }

    if (label?.label === "Guardar" || label?.label === "Guardar y publicar") {


      const actualizarDiagnostico = async () => {
        const deepEqual = (obj1, obj2) => {
          // Si ambos son el mismo objeto en memoria, son iguales
          if (obj1 === obj2) return true;
        
          // Si uno de los dos no es un objeto o es null, son diferentes
          if (obj1 == null || typeof obj1 !== "object" || obj2 == null || typeof obj2 !== "object") {
            return false;
          }
        
          // Obtenemos las claves de ambos objetos
          const keys1 = Object.keys(obj1);
          const keys2 = Object.keys(obj2);
        
          // Si tienen un número distinto de propiedades, no son iguales
          if (keys1.length !== keys2.length) return false;
        
          // Recorremos todas las propiedades para comparar
          for (let key of keys1) {
            // Si la propiedad en obj1 no es igual a la de obj2, devolvemos false
            if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
              return false;
            }
          }
        
          return true;
        }

        let diagUpdate = {};
    
        diagUpdate.id = datos.id;
        diagUpdate.fecha_toma_mue = fecha;
        if (diagnostico.lubricante.id !== selectedLubricante.value) diagUpdate.lubricante_id = selectedLubricante.value;
        if (diagnostico.horas_lubricante_mue !== horasUsoLubricante) diagUpdate.horas_lubricante_mue = horasUsoLubricante;
        if (diagnostico.horas_equipo_mue !== horasUsoEquipo) diagUpdate.horas_equipo_mue = horasUsoEquipo;
        if (diagnostico.diagnostico_mue !== msjDiagnostico) diagUpdate.diagnostico_mue = msjDiagnostico;
        if (diagnostico.sugerencias_mue !== msjSugerencias) diagUpdate.sugerencias_mue = msjSugerencias;
        if (selectedEstadoFinal /*&& diagnostico.estado_final.id !== selectedEstadoFinal.value*/) {
          diagUpdate.estado_final_id = selectedEstadoFinal.value;
        }
        if (selectedCausaFalla) {
          diagUpdate.causa_falla_id = selectedCausaFalla.value;
        }
        if (label?.label === "Guardar y publicar") diagUpdate.estado_mue = "Publicada"

        //console.log(diagUpdate);

        try {
          setIsLoading(true);
          await updateDiagnosticoByMuestraId(diagUpdate);

          // Validar resultados
          if(!deepEqual(diagnostico.resultado, resultados)){
            let resUpdate = {}
            resUpdate.muestra_id = datos.id;
            resUpdate.resultados = resultados.map( resultado => ({
              id: resultado.id,
              valor_res: resultado.valor_res,
              notificado_sn_res: resultado.notificado_sn_res
            }))

            console.log(resUpdate);
            // Hubo cambios en los resultados
            await updateResultadoByMuestraId(datos.id, resUpdate);
          }

          // Despues del update
          fetchDiagnostico(datos.id);
        } catch (error) {
          console.error("Error al actualizar el diagnóstico:", error);
        }
      };
    
      actualizarDiagnostico();
    }

  };

  const renderButtonHeader = () => {
    const render = buttonHeader?.map((res, i) => {
      
      if (diagnostico.estado_mue === "Publicada" && res.label === "Guardar y publicar"){
        return <></>
      } else {
        return <CustomButton
        key={i}
        label={res.label}
        size={res.size}
        startIcon={res.startIcon}
        className={res.className}
        classNameDiv={res.classNameDiv}
        onClick={() => handleClickButtonHeader(i, res)}
        />
      }
 
    })
    return render;
  }
  
  return (
    <StyledModalcomponentMuestras sx={{
      width: "75%",
      height: "90%",
      "@media (max-width: 768px)": {
        width: "95%",
      },
    }}>
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={(msj, resultado) => {
          setResModal({msj, resultado});
          setModalStatus(false);
        }}
        type={typeModal}
      />
      {isLoading ? <></> :
      <div className="diagMuestras">
        <div className="divLabel">
          <p className="titulo"> Diagnóstico de Muestras </p>
        </div>
        <form action="" className="formulario">
          <div className="columnaUno">
            <Card type="cardUno" datos={diagnostico}/>
            <div className="divInput">
              {<LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="inputDate"
                  label="Fecha Toma de Muestra"
                  placeholder="Fecha Toma de Muestra"
                  value={fecha}
                  renderInput={(params) => (
                    <Input
                      {...params}
                      className="input inputDate"
                      variant="outlined"
                      size="medium"
                    />
                  )}
                onChange={(e) => { setFecha(e) }}
                />
              </LocalizationProvider>
              }
              <Input
                reactSelect={true}
                placeholder={"Lubricante Actual del Punto de Toma"}
                className={"input"}
                classnamediv={"divInputPuntoDeToma"}
                options={lubricantes}
                value={selectedLubricante}
                onChange={(e) => {
                  setSelectedLubricante(e);
                }}
              />
              <Input
                placeholder={"Horas Uso Equipo"}
                className={"input"}
                classnamediv={"divInputEquipo"}
                value={horasUsoEquipo}
                onChange={(e) => {
                  setHorasUsoEquipo(e.target.value);
                }}
                label={"Horas Uso Equipo"}
              />
              <Input
                placeholder={"Horas Uso Lubricante"}
                className={"input"}
                classnamediv={"divInputLubricante"}
                value={horasUsoLubricante}
                onChange={(e) => {
                  setHorasUsoLubricante(e.target.value);
                }}
                label={"Horas Uso Lubricante"}
              />
              <Input
                placeholder={"Última intervención"}
                className={"input"}
                classnamediv={"divInputIntervencion"}
                value={lastIntervencion ? lastIntervencion?.tipo.description + " - " + lastIntervencion?.fecha_inte.slice(0, 10) : "El equipo no posee intervenciones"}
                //onChange={(e) => {
                //  setHorasUsoLubricante(e.target.value);
                //}}
                disabled={true}
                label={"Última intervención"}
              />
            </div>
            <div className="divInputt">
            <Input
                reactSelect={true}
                placeholder={"Estado Final"}
                className={"input"}
                classnamediv={"divInputEstadoFinal"}
                options={estadosFinales}
                value={selectedEstadoFinal}
                onChange={(e) => {
                  setSelectedEstadoFinal(e);
                }}
              />
            {<Input
                reactSelect={true}
                label={"Causa de Falla"}
                placeholder={"Causa de Falla"}
                className={"input"}
                classnamediv={"divInputCausaFalla"}
                options={causasFalla}
                value={selectedCausaFalla}
                onChange={(e) => {
                  setSelectedCausaFalla(e);
                }}
              />}
              <div className="divImagenes">

              {estadosFinales ? estadosFinales.map((estado, index) => (
                <div className="divImagen" onClick={()=>{handleButtonEstado(estado)}} style={selectedEstadoFinal === estado ? { border: `2px solid ${estado.color}`} : {}}>
                  <img src={`data:image/png;base64,${estado.image}`} style={{ width: '75px', height: '75px'}}/>

                </div>
              )) : <></>}
              </div>
            </div>
            <div className="divInputtt">
              <Input
                  reactSelect={true}
                  placeholder={"Mensaje Predefinido"}
                  className={"input"}
                  classnamediv={"divInputMensajePredefinido"}
                  options={mensajesPredefinidos}
                  value={selectedMensajePredefinido}
                  onChange={(e) => {
                    setSelectedMensajePredefinido(e);
                    setMsjDiagnostico(e.diagnostico)
                    setMsjSugerencias(e.sugerencia)
                  }}
                />
              <Input
                label={"Diagnóstico"}
                placeholder={"Diagnóstico"}
                className={"divInputMensaje"}
                classNamediv={"divInputMsjDiagnostico"}
                multiline={true}
                minRows={3}
                maxRows={3}
                value={msjDiagnostico}
                onChange={(e) => {
                  setMsjDiagnostico(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Input
                label={"Sugerencias"}
                placeholder={"Sugerencias"}
                className={"divInputMensaje"}
                classNamediv={"divInputMsjSugerencias"}
                multiline={true}
                minRows={3}
                maxRows={3}
                value={msjSugerencias}
                onChange={(e) => {
                  setMsjSugerencias(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="divInputtt">
              <DropZone key={"imagenes"}/>
              <DropZone key={"archivos"}/>
            </div>
          </div>
          <div className="columnaDos">
            <CardTwo
              onClick={() => { props.onClick() }}
              datos={diagnostico}
            />
            <div className="divHeaderTable">
              {renderButtonHeader()}
            </div>
            <Tables
              columns={columns}
              rows={resultados.map( resultado => ({
                resaltar: <Checkbox checked={resultado.notificado_sn_res === 'N' ? false : true} onChange={ (e) => handleChangeCheckbox(e, resultado)}/>,
                analisis: resultado.analisis.description,
                resultado: <Input value={resultado.valor_res} 
                onChange={(e) => {
                  handleChangeResultado(e.target.value, resultado);
                }}
                sx={resultado.notificado_sn_res === 'S' && selectedEstadoFinal ? {
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid " + selectedEstadoFinal.color, 
                    }
                  }
                } : {
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid " + colors.turquoise, 
                    }
                  }
                }}
                />,
                unidad: resultado.unidad.description
              }))}
              typeTable={'prop'}
              pagination={false}
              tableColor={true}
            />
          </div>
        </form>
      </div>}
    </StyledModalcomponentMuestras>
  )
}

export default DiagMuestrasModal

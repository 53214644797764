import React, { useState, useEffect, useRef } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import styled from "styled-components";
import Toast from "../../../../components/Toast/Toast";
import Modal2 from "../../../../components/Modal/Modal";
import Input from "../../../../components/Input/Input";
import {
  getClienteOptions,
  getEquiposOptions,
  deleteIntervecion,
  getIntervecionPaginated,
  getIntervecionSearch,
  getIntervencionByEquipo,
  getIntervencionByCliente,
  getIntervencionByClienteByEquipo,
  getEquipoOptionsByCliente,
} from "../../../../api/Clientes.js";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const columns = [
  { id: "fecha", label: "Fecha" },
  { id: "tipo", label: "Tipo Intervención" },
  { id: "intervencion", label: "Intervención" },
  { id: "equipo", label: "Equipo" },
  { id: "planta", label: "Planta" },
  { id: "cliente", label: "Cliente" },
  { id: "buttons" },
];

const buttonsHeadTable = [
  {
    label: "Nueva intervención",
    classname: "buttonHeaderTable",
  },
];

const Intervenciones = () => {
  const Navigate = useNavigate();
  const { state } = useLocation();
  const row = state ? state.row : null;

  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [intervenciones, setIntervenciones] = useState([]);
  const [equipos, setEquipos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [intervencionId, setIntevencionId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [selectedOptionequi, setSelectedOptionequi] = useState(null); //null
  const [selectedOptioncli, setSelectedOptioncli] = useState(null); //null
  const [key, setKey] = useState(1);
  const [prefilter, setPrefilter] = useState(row ? true : false);
  const [firstRender, setFirstRender] = useState(true);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [lastAction, setLastAction] = useState(null);
  //const prevStateRef = useRef({ paginaActual, orderBy, searchTerm });

  useEffect(() => {
    getEquiposOptions().then((res) => {
      setEquipos(
        res.data.map((res) => {
          return { label: res.desc_equi, value: res.id };
        })
      );
    });
    getClienteOptions().then((res) => {
      setClientes(
        res.data.map((res) => {
          return { label: res.desc_cli, value: res.id };
        })
      );
    });
    fetchIntervencionesPaginated(paginaActual, orderBy, searchTerm);
  }, []);

  useEffect(() => {
    if(selectedOptioncli === null) {
      getEquiposOptions().then((res) => {
        setEquipos(
          res.data.map((res) => {
            return { label: res.desc_equi, value: res.id };
          })
        );
      });
    } else {
      setSelectedOptionequi(null)
      getEquipoOptionsByCliente(selectedOptioncli.value).then((res) => {
        setEquipos(
          res.data.map((res) => {
            return { label: res.desc_equi, value: res.id}
          })
        )
      })
    }
  }, [selectedOptioncli])

  useEffect(() => {

    if(selectedOptioncli === null && selectedOptionequi === null) {
      fetchIntervencionesPaginated(paginaActual,orderBy,searchTerm, lastAction)
      return;
    }

    if(selectedOptioncli !== null && selectedOptionequi === null) {
      fetchIntervencionByCliente(selectedOptioncli.value,paginaActual,orderBy,searchTerm, lastAction);
      return;
    }

    if(selectedOptioncli === null && selectedOptionequi !== null) {
      fetchIntervencionByEquipo(selectedOptionequi.value,paginaActual,orderBy,searchTerm, lastAction);
      return;
    }

    if(selectedOptioncli !== null && selectedOptionequi !== null) {
      fetchIntervencionByClienteByEquipo(selectedOptioncli.value, selectedOptionequi.value,paginaActual,orderBy,searchTerm, lastAction)
      return;
    }

  }, [selectedOptioncli, selectedOptionequi, paginaActual, orderBy, searchTerm, reloadFlag])

  // useEffect(() => {
  //   if (selectedOptioncli) {
  //     getEquiposByClientes(selectedOptioncli.value).then((res) => {
  //       setEquipos(
  //         res.data.map((res) => {
  //           return { label: res.desc_equi, value: res.id };
  //         })
  //       );
  //     });

  //     setSelectedOptionequi([]);
  //   } else {
  //     fetchIntervencionesPaginated(paginaActual, orderBy, "");
  //     setSelectedOptionequi([]);
  //   }
  // }, [selectedOptioncli]);

  // useEffect(() => {
  //   if (selectedOptionequi && selectedOptionequi.length > 0) {
  //     fetchIntervencionByEquipo(selectedOptionequi.value);
  //   }
  // }, [selectedOptionequi]);

  // useEffect(() => {
  //   fetchIntervencionesPaginated(paginaActual, orderBy, searchTerm, "");
  // }, [searchTerm, paginaActual, orderBy]);

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setIntevencionId(valueRow.id);
      setModalStatus2(true);
    }
  };

  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };

  const fetchIntervencionesPaginated = async (
    pagina,
    order,
    search,
    action
  ) => {
    const res =
      search === ""
        ? await getIntervecionPaginated(pagina, order)
        : await getIntervecionSearch(pagina, order, search);

    if (action !== "") {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      if (res.data.totalPages > 0) {
        setPaginaActual(
          res.data.totalPages < paginaActual
            ? res.data.totalPages
            : paginaActual
        );
      }
    }
    if (action === "new" && intervenciones.length + 1 === 21) {
      setPaginaActual(res.data.totalPages);
    }

    if (action === "delete") {
      //setKey(key + 1);
      setMsjToast("Intervencion eliminada con exito.");
      setSuccess(true);
    }
    setIntervenciones(
      res.data.items.map((res) => {
        return {
          id: res.id,
          observaciones: res.observacion_inte,
          fecha: res.fecha_inte,
          tipo: res.tipo.description,
          intervencion: res.desc_inte,
          equipo: res.equipo.description,
          planta: res.planta.description,
          cliente: res.cliente.description,
          clienteId: res.cliente.id,
          equipoId: res.equipo.id,
          plantaId: res.planta.id,
          tipoId: res.tipo.id,
        };
      })
    );
  };

  const fetchIntervencionByEquipo = async (
    id,
    pagina,
    order,
    search,
    action
  ) => {
    const res = await getIntervencionByEquipo(id, pagina, order, search);

    // console.log("Res: ", res.data.items);

    if (action !== "") {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      if (res.data.totalPages > 0) {
        setPaginaActual(
          res.data.totalPages < paginaActual
            ? res.data.totalPages
            : paginaActual
        );
      }
    }

    if (action === "new" && intervenciones.length + 1 === 21) {
      setPaginaActual(res.data.totalPages);
    }

    if (action === "delete") {
      //setKey(key + 1);
      setMsjToast("Intervencion eliminada con exito.");
      setSuccess(true);
    }

    setIntervenciones(
      res.data.items.map((res) => {
        return {
          id: res.id,
          observaciones: res.observacion_inte,
          fecha: res.fecha_inte,
          tipo: res.tipo.description,
          intervencion: res.desc_inte,
          equipo: res.equipo.description,
          planta: res.planta.description,
          cliente: res.cliente.description,
          clienteId: res.cliente.id,
          equipoId: res.equipo.id,
          plantaId: res.planta.id,
          tipoId: res.tipo.id,
        };
      })
    );
  };

  const fetchIntervencionByCliente = async (
    id,
    pagina,
    order,
    search,
    action
  ) => {
    const res = await getIntervencionByCliente(id, pagina, order, search);

    if (action !== "") {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      if (res.data.totalPages > 0) {
        setPaginaActual(
          res.data.totalPages < paginaActual
            ? res.data.totalPages
            : paginaActual
        );
      }
    }

    if (action === "new" && intervenciones.length + 1 === 21) {
      setPaginaActual(res.data.totalPages);
    }

    if (action === "delete") {
      //setKey(key + 1);
      setMsjToast("Intervencion eliminada con exito.");
      setSuccess(true);
    }

    setIntervenciones(
      res.data.items.map((res) => {
        return {
          id: res.id,
          observaciones: res.observacion_inte,
          fecha: res.fecha_inte,
          tipo: res.tipo.description,
          intervencion: res.desc_inte,
          equipo: res.equipo.description,
          planta: res.planta.description,
          cliente: res.cliente.description,
          clienteId: res.cliente.id,
          equipoId: res.equipo.id,
          plantaId: res.planta.id,
          tipoId: res.tipo.id,
        };
      })
    );
  };

  const fetchIntervencionByClienteByEquipo = async (
    idCli,
    idEquipo,
    pagina,
    order,
    search,
    action
  ) => {
    const res = await getIntervencionByClienteByEquipo(
      idCli,
      idEquipo,
      pagina,
      order,
      search
    );

    if (action !== "") {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      if (res.data.totalPages > 0) {
        setPaginaActual(
          res.data.totalPages < paginaActual
            ? res.data.totalPages
            : paginaActual
        );
      }
    }

    if (action === "new" && intervenciones.length + 1 === 21) {
      setPaginaActual(res.data.totalPages);
    }

    if (action === "delete") {
      //setKey(key + 1);
      setMsjToast("Intervencion eliminada con exito.");
      setSuccess(true);
    }

    setIntervenciones(
      res.data.items.map((res) => {
        return {
          id: res.id,
          observaciones: res.observacion_inte,
          fecha: res.fecha_inte,
          tipo: res.tipo.description,
          intervencion: res.desc_inte,
          equipo: res.equipo.description,
          planta: res.planta.description,
          cliente: res.cliente.description,
          clienteId: res.cliente.id,
          equipoId: res.equipo.id,
          plantaId: res.planta.id,
          tipoId: res.tipo.id,
        };
      })
    );
  };

  const handleChangeCliente = (value) => {
    if (!value) {
      // Si se borra el valor del input, reseteamos la tabla
      setPrefilter(false);
      setSelectedOptioncli(null);

      /*if (selectedOptionequi !== null) {
        fetchIntervencionByEquipo(
          selectedOptionequi.value,
          paginaActual,
          orderBy,
          "",
          "filter"
        );
      } else {
        fetchIntervencionesPaginated(
          paginaActual,
          orderBy,
          "",
          "filter"
        );
      }*/
    } else {
      setSelectedOptioncli(value);

      /*if (selectedOptionequi !== null) {
        fetchIntervencionByClienteByEquipo(
          value.value,
          selectedOptionequi.value,
          paginaActual,
          orderBy,
          searchTerm,
          "filter"
        );
      } else {
        fetchIntervencionByCliente(
          value.value,
          paginaActual,
          orderBy,
          searchTerm,
          "filter"
        );
      }*/
    }
    setPrefilter(true);
  };

  const handleChangeEquipo = (value) => {
    if (!value) {
      setPrefilter(false);
      setSelectedOptionequi(null);

      /*if (selectedOptioncli !== null) {
        fetchIntervencionByCliente(
          selectedOptioncli.value,
          paginaActual,
          orderBy,
          searchTerm,
          "filter"
        );
      } else {
        // console.log(1);
        fetchIntervencionesPaginated(
          paginaActual,
          orderBy,
          searchTerm,
          "filter"
        );
      }*/
    } else {
      // console.log("value: ", value);
      // console.log("selectedOptioncli: ", selectedOptioncli);
      setSelectedOptionequi(value);

      /*if (selectedOptioncli !== null) {
        fetchIntervencionByClienteByEquipo(
          selectedOptioncli.value,
          value.value,
          paginaActual,
          orderBy,
          searchTerm,
          "filter"
        );
      } else {
        fetchIntervencionByEquipo(
          value.value,
          paginaActual,
          orderBy,
          searchTerm,
          "filter"
        );
      }*/
    }
    setPrefilter(true);
  };

  const handleBuscador = (value) => {
    setSearchTerm(value);
    /*if (selectedOptioncli !== null && selectedOptionequi === null) {
      fetchIntervencionByCliente(
        selectedOptioncli.value,
        paginaActual,
        orderBy,
        value,
        "search"
      );
    } else if (selectedOptioncli === null && selectedOptionequi !== null) {
      fetchIntervencionByEquipo(
        selectedOptionequi.value,
        paginaActual,
        orderBy,
        value,
        "search"
      );
    } else if (selectedOptioncli !== null && selectedOptionequi !== null) {
      fetchIntervencionByClienteByEquipo(
        selectedOptioncli.value,
        selectedOptionequi.value,
        paginaActual,
        orderBy,
        value,
        "search"
      );
    } else {
      fetchIntervencionesPaginated(paginaActual, orderBy, value, "search");
    }*/
  };

  const handleDelete = (id) => {
    deleteIntervecion(id)
      .then(() => {
        setIntevencionId("");
        let paginaActualTemp = paginaActual;
        if (intervenciones.length - 1 === 0 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        setLastAction("delete")
        setReloadFlag(!reloadFlag)
        return;
      })
      .catch((err) => {
        //setKey(key + 1);
        setMsjToast("Error al eliminar la intervencion.");
        setError(true);
      });
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  return (
    <Styled>
      <div className="toast">
        {success ? (
          <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            key={key}
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        ) : null}
      </div>

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          //setKey(key + 1);
          setMsjToast(msj);
          if (status === "success") {
            if(msj.includes("cread")){setLastAction("new")}
            setSuccess(true);
            setReloadFlag(!reloadFlag)
          } else if (status === "error") {
            setError(true);
          }
          setModalStatus(false);
        }}
        type={"intervenciones"}
      />

      <div className="compexterno">
        <HeaderTable
          styledHead="clientes"
          clientes
          title="Intervenciones"
          datosButtons={buttonsHeadTable}
          valueBuscador={(value) => handleBuscador(value)}
          onClickButton={() => {
            handleClickButtonHeader();
          }}
          multiInputs
          selectInput={(e) => {
            setSearchTerm(e.target.value.label);
          }}
          buttonSet={() => {
            setSearchTerm("");
          }}
          clean
          search
          value={searchTerm}
          isClearable={() => {
            setSearchTerm(""); 
            /*fetchIntervencionesPaginated(
              paginaActual,
              orderBy,
              "", 
              "filter" 
            );*/
          }}
        />
        <div className="compinterno">
          <Input
            isClearable
            reactSelect
            placeholder={"Seleccione Cliente"}
            options={clientes}
            stylesHeader
            onChange={(e) => handleChangeCliente(e)}
            value={selectedOptioncli}
          />
          <Input
            isClearable
            reactSelect 
            placeholder={"Seleccione Equipo"}
            options={equipos}
            stylesHeader
            onChange={(e) => {
              handleChangeEquipo(e);
            }}
            value={selectedOptionequi}
          />
        </div>
      </div>

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={intervenciones}
        cRows={20}
        totalRows={itemsCount}
        actualpage={paginaActual}
        pagination={true}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      // onClickSwitch={(id)=> alert(id)}
      />

      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDelete(intervencionId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default Intervenciones;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
    //height: 100vh;
  }

  & .compexterno {
    position: relative;
  }
  & .compinterno {
    width: 400px;
    position: absolute;
    left: 29%;
    top: 55%;
    display: flex;
    flex-direction: row;

    gap: 10px;

    & .divInput {
      width: 100%;
    }
  }
`;

import React, { useReducer, useState, useEffect } from "react";
import Input from "../../../../Input/Input";
import CustomButton from "../../../../Button/CustomButton.tsx";
import Tables from "../../../../Tables/Tables";
import dayjs from "dayjs";
import Toast from "../../../../Toast/Toast.jsx";
import Preloader from "../../../../Preloader/Preloader.jsx";
import moment from "moment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { StyledModalTableClientes } from "../../../Styled.jsx";
import {
  getTipoEventos,
  getTipoEventosOptions,
} from "../../../../../api/Configuracion.js";
import {
  getClienteOptions,
  getPlantaOptions,
  getEquiposOptions,
  updateIntervecion,
  createIntervencion,
  getPlantabyCliente2,
  getEquiposByPlantas2,
  getIntervencionByEquipo2,
  getLastIntervencionesByEquipo,
} from "../../../../../api/Clientes.js";

const columns = [
  { id: "fecha", label: "Fecha" },
  { id: "tipodeevento", label: "Tipo de evento" },
];

const rows = [
  {
    fecha: "28/04/1991",
    tipodeevento: "cambio de aceite",
  },
  {
    fecha: "28/04/1991",
    tipodeevento: "hola",
  },
  {
    fecha: "28/04/1991",
    tipodeevento: "chau",
  },
];

const Intervenciones = (props) => {
  const propsIntervenciones = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos };
  };
  const { ver, editar, datos } = propsIntervenciones(props.datos);

  const [formCompleto, setFormCompleto] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [clientes, setClientes] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [tipoEventos, setTipoEventos] = useState([]);
  const [equipos, setEquipos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [componentes, setComponente] = useState([]);
  const [lubricantes, setLubricantes] = useState([]);
  const [clienteSeleccionado, setClienteSeleccionado] = useState("");
  const [plantaSeleccionado, setPlantaSeleccionado] = useState("");
  const [intervencionesEquipos, setIntervencionesEquipos] = useState([]);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [selectedOptioncli, setSelectedOptioncli] = useState(null);
  const [selectedOptionequi, setSelectedOptionequi] = useState(null);
  const [selectedOptionepla, setSelectedOptionpla] = useState(null);
  const [selectedOptiontpeve, setSelectedOptiontpeve] = useState(null);
  const [form, setForm] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_FIELD":
          return { ...state, [action.field]: action.value };
        default:
          return state;
      }
    },
    {
      id: datos.id ?? "",
      cliente: datos.clienteId ?? "",
      planta: datos.plantaId ?? "",
      equipo: datos.equipoId ?? "",
      tipodeevento: datos.tipoId ?? "",
      clienteId: datos.cliente ?? "",
      plantaId: datos.planta ?? "",
      equipoId: datos.equipo ?? "",
      tipodeeventoId: datos.tipoId ?? "",
      fechadeevento: dayjs(datos.fechadeevento) ?? dayjs(),
      detalledelevento: datos.intervencion ?? "",
      observaciones: datos.observaciones ?? "",
    }
  );

  useEffect(() => {
    if (
      selectedOptioncli === "" ||
      selectedOptioncli === null ||
      selectedOptioncli === undefined
    ) {
      return;
    }
    if (loadingEdit) {
      getPlantabyCliente2(selectedOptioncli?.value).then((res) => {
        let aux = res.data.map((res) => {
          return { label: res.desc_pla, value: res.id };
        });
        setPlantas(aux);

        const matchOption = aux.find((option) => option.label === datos.planta);
        setSelectedOptionpla(matchOption);
      });
      return;
    }

    if (form.cliente !== "" && !ver && selectedOptioncli !== null) {
      setSelectedOptionequi("");
      setSelectedOptionpla("");
      setEquipos([]);
      setPlantas([]);
      getPlantabyCliente2(selectedOptioncli.value).then((res) => {
        setPlantas(
          res.data.map((res) => {
            return { label: res.desc_pla, value: res.id };
          })
        );
      });
    }
  }, [selectedOptioncli]);

  useEffect(() => {
    if (
      selectedOptionepla === "" ||
      selectedOptionepla === null ||
      selectedOptionepla === undefined
    ) {
      return;
    }

    if (loadingEdit) {
      // console.log(selectedOptionepla);
      getEquiposByPlantas2(selectedOptionepla?.value).then((res) => {
        let aux = res.data.map((res) => {
          return { label: res.desc_equi, value: res.id };
        });
        setEquipos(aux);

        const matchOption = aux.find((option) => option.label === datos.equipo);
        setSelectedOptionequi(matchOption);
        setLoadingEdit(false);
      });
      return;
    }

    // if (
    //   selectedOptionepla === "" ||
    //   selectedOptionepla === null ||
    //   selectedOptionepla === undefined
    // ) {
    //   return;
    // }
    if (form.cliente !== "" && !ver) {
      // console.log(selectedOptionepla);
      setSelectedOptionequi("");
      getEquiposByPlantas2(selectedOptionepla?.value).then((res) => {
        setEquipos(
          res.data.map((res) => {
            return { label: res.desc_equi, value: res.id };
          })
        );
        //setIntervencionesEquipos([]);
      });
    }
  }, [selectedOptionepla]);

  useEffect(() => {
    if (selectedOptioncli === null) {
      return;
    } else if (
      Array.isArray(selectedOptionequi) &&
      selectedOptionequi.length === 0
    ) {
      return;
    } else if (isNaN(selectedOptionequi.value)) {
      return;
    }

    if (form.cliente !== "") {
      console.log(selectedOptionequi);

      getLastIntervencionesByEquipo(selectedOptionequi.value).then((res) => {
        setIntervencionesEquipos(
          res.data.map((res) => {
            return {
              fecha: res.fecha_inte,
              tipodeevento: res.tipo.description,
            };
          })
        );
      });
    }
  }, [selectedOptionequi]);

  useEffect(() => {
    const campoValido = (campo) => form[campo] !== "";

    const camposaValidar = [
      "cliente",
      "planta",
      "equipo",
      "tipodeevento",
      "fechadeevento",
      "detalledelevento",
      "observaciones",
    ];
    const formularioCompleto = camposaValidar.every(campoValido);

    if (
      selectedOptioncli === null ||
      selectedOptioncli === "" ||
      selectedOptionepla === null ||
      selectedOptionepla === "" ||
      selectedOptionequi === null ||
      selectedOptionequi === ""
    ) {
      setFormCompleto(false);
    } else {
      setFormCompleto(formularioCompleto);
    }
  }, [form, selectedOptioncli, selectedOptionepla, selectedOptionequi]);

  useEffect(() => {
    // console.log(datos);
    const fetchclientes = async () => {
      const res = await getClienteOptions();
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_cli,
      }));
      setClientes(unidades);

      if (datos) {
        const matchOption = unidades.find(
          (option) => option.label === datos.cliente
        );
        if (matchOption) {
          setSelectedOptioncli(matchOption);
        } else if (unidades.length > 0 && editar) {
          setSelectedOptioncli(unidades[0]);
        }
      } else if (unidades.length > 0 && editar) {
        setSelectedOptioncli(unidades[0]);
      }
      /*if (ver || editar) {
        getIntervencionByEquipo2(datos.equipoId).then((res) => {
          setIntervencionesEquipos(
            res.data.map((res) => {
              return {
                fecha: moment(res.fecha_inte).format("DD-MM-YYYY"),
                tipodeevento: res.tipo.description,
              };
            })
          );
        });
      }*/
    };

    const fetchEquipos = async () => {
      const res = await getEquiposOptions();

      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_equi,
      }));
      setEquipos(unidades);
      console.log(unidades);

      if (datos) {
        const matchOption = unidades.find(
          (option) => option.value === datos.equipoId
        );
        console.log(matchOption);
        if (matchOption) {
          setSelectedOptionequi(matchOption);
        } else if (unidades.length > 0 && editar) {
          setSelectedOptionequi(unidades[0]);
        }
      } else if (unidades.length > 0 && editar) {
        setSelectedOptionequi(unidades[0]);
      }
    };

    /*getPlantaOptions().then((res) => {
      setPlantas(
        res.data.map((res) => {
          return { label: res.desc_pla, value: res.id };
        })
      );
    });*/

    const fetchPlantas = async () => {
      const res = await getPlantaOptions();
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_pla,
      }));
      setPlantas(unidades);

      if (datos) {
        const matchOption = unidades.find(
          (option) => option.label === datos.planta
        );
        if (matchOption) {
          setSelectedOptionpla(matchOption);
        } else if (unidades.length > 0 && editar) {
          setSelectedOptionpla(unidades[0]);
        }
      } else if (unidades.length > 0 && editar) {
        setSelectedOptionpla(unidades[0]);
      }
    };

    const fetchTipoEventos = async () => {
      const res = await getTipoEventosOptions();
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_tpeve,
      }));
      setTipoEventos(unidades);

      if (datos) {
        const matchOption = unidades.find(
          (option) => option.label === datos.tipo
        );
        if (matchOption) {
          setSelectedOptiontpeve(matchOption);
        } else if (unidades.length > 0 && editar) {
          setSelectedOptiontpeve(unidades[0]);
        }
      } else if (unidades.length > 0 && editar) {
        setSelectedOptiontpeve(unidades[0]);
      }
    };

    const fetchEdit = async () => {
      const resClientes = await getClienteOptions();
      const unidades = resClientes?.data?.map((item) => ({
        value: item.id,
        label: item.desc_cli,
      }));
      setClientes(unidades);

      const matchOption = unidades.find(
        (option) => option.label === datos.cliente
      );
      setSelectedOptioncli(matchOption);
    };

    if (editar) {
      setLoadingEdit(true);
      fetchEdit();
    } else {
      fetchclientes();
      if (ver) {
        fetchPlantas();
      }
      if (ver) {
        fetchEquipos();
      }
    }
    fetchTipoEventos();

    /*if (form.cliente !== "") {
      setClienteSeleccionado({ label: form.cliente, value: form.clienteId });
      setSelectedOptioncli({ label: form.cliente, value: form.clienteId });
    }
    if (form.planta !== "") {
      setSelectedOptionpla({ label: form.planta, value: form.plantaId });
      setPlantaSeleccionado({ label: form.planta, value: form.plantaId });
    }
    if (form.equipo !== "") {
      // setEquipoSeleccionado(form.equipoId)
    }*/
  }, []);

  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === "checkbox" ? checked : value;
      setForm({ type: "SET_FIELD", field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
      if (name === "cliente") {
        //setClienteSeleccionado(value);
        setSelectedOptioncli(e);
        setForm({ type: "SET_FIELD", field: name, value: value });
      } else if (name === "equipo") {
        setSelectedOptionequi(e);
        setForm({ type: "SET_FIELD", field: name, value: value });
      } else if (name === "planta") {
        setPlantaSeleccionado(value);
        setSelectedOptionpla(e);
        setForm({ type: "SET_FIELD", field: name, value: value });
      } else if (name === "tipodeevento") {
        setSelectedOptiontpeve(e);
        setForm({ type: "SET_FIELD", field: name, value: value });
      }
    }
  };

  const handleChangeCalendar = (date, nameCalendar) => {
    const name = nameCalendar;
    const value = dayjs(date); // Asegúrate de usar dayjs para la fecha

    if (ver) {
      return;
    }

    setForm({ type: "SET_FIELD", field: name, value: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formattedData = {
      id: form.id,
      codigo_inte: "codigo", //no tengo
      abrev_inte: "abrev",
      desc_inte: form.detalledelevento,
      fecha_inte: new Date(), //@todo tiene que ir formateada a string asi como esta
      fecha_inte_str: "32132",
      observacion_inte: form.observaciones,
      tipo_id: form.tipodeevento.toString(),
      equipo_id: form.equipo.toString(),
      cliente_id: form.cliente.toString(),
      planta_id: form.planta.toString(),
      created_by: "dasfdasd",
      created_date: new Date(),
      last_modified_by: "1",
      last_modified_date: new Date(),
      status: "SI",
      eliminado: false,
      tenantId: "1",
    };

    if (editar) {
      updateIntervecion(formattedData)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Intervención modificada con exito.",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se pudo modificar la intervención.",
            status: "error",
          });
        });
    } else {
      createIntervencion(formattedData)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Intervención creada con exito.",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se pudo crear la intervención.",
            status: "error",
          });
        });
    }
  };

  const titulo = () => {
    const titulo = ver
      ? "Ver intervenciones"
      : editar
      ? "Editar intervenciones"
      : "Crear o editar intervenciones";
    return titulo;
  };

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={"Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!formCompleto}
          />
        )}
      </>
    );
  };

  return (
    <StyledModalTableClientes style={{ height: "auto" }}>
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />

      <div className="toast">
        {" "}
        {success ? (
          <Toast title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            type={"error"}
            title={
              "Error al " +
              (editar ? "modificar" : "crear") +
              "El punto de toma ?"
            }
            close={() => setError(false)}
          />
        ) : null}
      </div>
      <div className="intervenciones">
        <div className="divLabel">
          <p className="titulo"> {titulo()} </p>
        </div>

        <form className="formulario" onSubmit={handleSubmit}>
          <div className="divInputs">
            <div className="divUno">
              <div style={{ width: "50%" }}>
                <Input
                  reactSelect
                  options={clientes}
                  value={selectedOptioncli}
                  onChange={(e) => handleChange(e, "cliente")}
                  isDisabled={ver && true}
                  placeholder={ver ? "Cliente" : "Selecciona el Cliente"}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Input
                  reactSelect
                  options={plantas}
                  value={selectedOptionepla}
                  onChange={(e) => handleChange(e, "planta")}
                  isDisabled={
                    selectedOptioncli === null ||
                    selectedOptioncli === "" ||
                    ver
                  }
                  placeholder={ver ? "Planta" : "Selecciona la Planta"}
                />
              </div>
            </div>
            <div className="divDos">
              <div style={{ width: "50%" }}>
                <Input
                  reactSelect
                  options={equipos}
                  value={selectedOptionequi}
                  onChange={(e) => handleChange(e, "equipo")}
                  isDisabled={
                    selectedOptionepla === null ||
                    selectedOptionepla === "" ||
                    ver
                  }
                  placeholder={ver ? "Equipo" : "Selecciona el Equipo"}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Input
                  reactSelect
                  options={tipoEventos}
                  value={selectedOptiontpeve}
                  onChange={(e) => handleChange(e, "tipodeevento")}
                  isDisabled={ver}
                  placeholder={
                    ver ? "Tipo de Evento" : "Selecciona tipo de Evento"
                  }
                />
              </div>
            </div>
            <div className="divTres">
              <div className="divDate" style={{ width: "50%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      className="inputDate"
                      label="Fecha del Evento"
                      placeholder="Fecha del Evento"
                      value={form.fechadeevento}
                      onChange={(date) =>
                        handleChangeCalendar(date, "fechadeevento")
                      }
                      disabled={ver}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>

              <Input
                value={form.detalledelevento}
                label="Detalle del evento"
                classnamediv="divDetalleDelEvento"
                className="input inputDetalleDelEvento"
                variant="outlined"
                size={"medium"}
                name="detalledelevento"
                disabled={ver}
                onChange={handleChange}
              />
            </div>
            <div className="divCuatro">
              <Input
                value={form.observaciones}
                label="Observaciones"
                classnamediv="divObservaciones divInput"
                className="input inputObservaciones"
                variant="outlined"
                size={"medium"}
                name="observaciones"
                disabled={ver}
                onChange={handleChange}
              />
            </div>
            <div className="divButtonsItem">{renderButtons()}</div>
          </div>

          <div className="divTabla">
            <div className="divTitulo">
              <p className="titulo">Ultimas Intervenciones Registradas</p>
            </div>
            {selectedOptionequi?.value && (
              <Tables
                typeTable={"prop"}
                columns={columns}
                rows={intervencionesEquipos}
                cRows={7}
                page={(value) => {
                  //console.log(value);
                }}
              />
            )}
          </div>
        </form>
      </div>
    </StyledModalTableClientes>
  );
};

export default Intervenciones;

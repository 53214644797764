import React from 'react';
import CustomButton from '../../../../Button/CustomButton.tsx';

import { StyledModalcomponentMuestras } from '../../../Styled';

const NotiOrdenesDeTrabajo = (props) => {

  const propsNoti = (datos) => {
    const titulo = datos?.titulo;

    return { titulo }
  }

  const { titulo } = propsNoti(props.datos);
 
  
  let estado = props.datos.datos.estadoCompleto

  const renderLabel = () => {
    let tituloLabel = null;
    let descripcionLabel = null;

    tituloLabel = `${estado.accion_ef_ot} OT`;
    if(estado.codigo_template_email_ef_ot!== ""){
      descripcionLabel = `Al ${estado.accion_ef_ot} la OT, se enviará un mail al cliente, indicando que se recibieron las muestras y se inició el proceso de análisis de las mismas.` 
    }    
  
    if (estado.accion_ef_ot === "Finalizar") {
      descripcionLabel = "Al Finalizar la OT, se enviará un mail al cliente, indicando que se realizaron todos los análisis correspondientes a todas las muestras de la OT."
    }

    return { tituloLabel, descripcionLabel };
  }

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          label={"Si"}
          variant="contained"
          className={`buttonColorDefault btn`}
          type="submit"
          onClick={props.onClick2}
        // disabled={!editar ? !formCompleto : false}
        />
        <CustomButton
          onClick={props.onClick}
          label={"No"}
          variant="contained"
          className={`buttonColorDisabled btn`}
        />
      </>
    );
  };

  return (
    <StyledModalcomponentMuestras>
      <div className="notiOrdenesDeTrabajo">
        <div className="divLabel">
          <p className="titulo">{renderLabel().tituloLabel}</p>
        </div>
        <div className="divBody">
         {renderLabel().descripcionLabel && <div className="divDescripcion">
            <p className="descripcion">{renderLabel().descripcionLabel}</p>
          </div>}
          <p className="confirmacion">Confirma el cambio de estado?</p>
          <div className="divButtonsItem">
            {renderButtons()}
          </div>
        </div>
      </div>
    </StyledModalcomponentMuestras>
  )
}

export default NotiOrdenesDeTrabajo
import React, { useState, useEffect, useRef } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import styled from "styled-components";
import {
  deletePuntoTomas,
  getPuntoTomasPaginated,
  getPuntoTomasSearch,
  getEquipos,
  getPlantaOptions,
  getPuntosTomasByEquipo,
  getEquiposByPlantas,
  getPuntosTomasByPlanta,
  getEquiposByPlantasOption,
  getEquiposOptions,
  getPuntosTomasByEquipoPlanta
} from "../../../../api/Clientes.js";
import {
  getComponente,
  getLubricantes,
} from "../../../../api/Configuracion.js";
import Toast from "../../../../components/Toast/Toast";
import Modal2 from "../../../../components/Modal/Modal";
import { useLocation } from "react-router-dom";
import Input from "../../../../components/Input/Input";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const columns = [
  { id: "codigo", label: "Código" },
  { id: "denominacion", label: "Denominación" },
  { id: "lubricante", label: "Lubricante" },
  { id: "componente", label: "Componente" },
  { id: "equipo", label: "Equipo" },
  { id: "buttons" },
];

const buttonsHeadTable = [
  {
    label: "Equipos",
    icon: <ArrowBackIcon />,
    classname: "buttonColorTable",
    navigate: "/clientes/equipos",
    size: "medium",
  },
  {
    label: "Nuevo punto de toma",
    classname: "buttonHeaderTable",
  },
];

const PuntosDeToma = () => {
  const { state } = useLocation();
  const row = state ? state.row : null;

  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [puntosTomas, setPuntosTomas] = useState([]);
  const [puntosTomasId, setPuntosTomasId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [equipo, setEquipo] = useState([]);
  const [componente, setComponente] = useState([]);
  const [lubricantes, setLubricantes] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [selectedOptionequi, setSelectedOptionequi] = useState(null);
  const [selectedOptionpla, setSelectedOptionpla] = useState(null);
  const [key, setKey] = useState(1);
  const isInitialRender = useRef(true);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    //fetchComponente();
    //fetchLubricantes();
    fetchPlantas();
    fetchEquipos();

    if (row && isInitialRender.current) {
      //setSearchTerm(row.denominacion);
      setSelectedOptionequi({ label: row.denominacion, value: row.id });
      setSelectedOptionpla({ label: row.planta, value: row.plantaId });
      fetchPuntosTomasByEquipo(row.id);
    } else {
      setFirstLoad(false);
      fetchPuntosTomasPaginated(paginaActual, orderBy, searchTerm, "");
    }
  }, []);

  useEffect(() => {
    if (!firstLoad) {
      if (selectedOptionequi) {
        fetchPuntosTomasByEquipo(selectedOptionequi.value);
      } else {
        if (selectedOptionpla) {
          fetchPuntosTomasByPlanta(selectedOptionpla.value);
        } else {
          fetchPuntosTomasPaginated(paginaActual, orderBy, searchTerm, "");
        }
      }
    }

  }, [selectedOptionequi, selectedOptionpla, paginaActual, orderBy, searchTerm]);

  useEffect(() => {
    if (!firstLoad) {
      if (selectedOptionpla) {
        fetchEquiposByPlantas(selectedOptionpla.value);
      } else {
        //fetchPuntosTomasPaginated(paginaActual, orderBy, searchTerm, "");
        fetchEquipos();
      }
    }

  }, [selectedOptionpla]);

  /*useEffect(() => {
    if(selectedOptionpla !== null && selectedOptionpla !== "") {
      if(selectedOptionequi !== null && selectedOptionequi !== "") {
        fetchPuntosTomasByEquipo(selectedOptionequi.value)
      } else {fetchPuntosTomasByPlanta(selectedOptionpla.value)}
    } else {
      fetchPuntosTomasPaginated(paginaActual, orderBy, searchTerm, "");
    }
  }, [paginaActual, orderBy, searchTerm]);*/

  const fetchPuntosTomasPaginated = async (pagina, order, search, action, code) => {

    const res = await getPuntoTomasPaginated(pagina, order, search)

    setPageCount(res.data.totalPages);
    setItemsCount(res.data.totalItems);

    if (code === "create") { setPaginaActual(res.data.totalPages) }
    if (paginaActual > res.data.totalPages) { setPaginaActual(1) }

    if (action === "delete") {
      //setKey(key + 1);
      setMsjToast("Punto de Toma eliminado con exito.");
      setSuccess(true);
    }
    let filtroSeleccionado = "";
    if(selectedOptionpla !== null && selectedOptionpla !== '' || selectedOptionequi !== null && selectedOptionequi !== '') {
      filtroSeleccionado = {planta:selectedOptionpla, equipo:selectedOptionequi}
    }
    setPuntosTomas(
      res.data.items.map((res) => {
        return {
          equipoId: res.equipo_id.id,
          lubricanteId: res.lubricante_id.id,
          id: res.id,
          codigo: res.codigo_pto,
          denominacion: res.desc_pto,
          lubricante: res.lubricante_id.description,
          componente: res.componente_id.description,
          componenteId: res.componente_id.id,
          equipo: res.equipo_id.description,
          tagExterno: res.codigo_externo_pto,
          clienteId: res.equipo_id.planta.cliente.id,
          cliente: res.equipo_id.planta.cliente.description,
          plantaId: res.equipo_id.planta.id,
          planta: res.equipo_id.planta.description,
          filtro: filtroSeleccionado,
        };
      })
    );
  };

  const fetchEquiposByPlantas = async (id) => {
    const res = await getEquiposByPlantasOption(id);
    setEquipo(
      res.data.map((res) => {
        return { label: res.desc_equi, value: res.id };
      })
    );
  };

  const fetchPuntosTomasByEquipo = async (id, action) => {
    const res = await getPuntosTomasByEquipo(paginaActual, orderBy, searchTerm, id).then(setFirstLoad(false));
    let filtroSeleccionado = ""
    if(selectedOptionequi !== null && selectedOptionequi !== '') {
      filtroSeleccionado = {planta:selectedOptionpla, equipo:selectedOptionequi}
    }

    setPuntosTomas(
      res.data.items.map((res) => {
        return {
          equipoId: res.equipo_id.id,
          lubricanteId: res.lubricante_id.id,
          id: res.id,
          codigo: res.codigo_pto,
          denominacion: res.desc_pto,
          lubricante: res.lubricante_id.description,
          componente: res.componente_id.description,
          componenteId: res.componente_id.id,
          equipo: res.equipo_id.description,
          tagExterno: res.codigo_externo_pto,
          clienteId: res.equipo_id.planta.cliente.id,
          cliente: res.equipo_id.planta.cliente.description,
          plantaId: res.equipo_id.planta.id,
          planta: res.equipo_id.planta.description,
            filtro: filtroSeleccionado
        };
      })

    )
    setPageCount(res.data.totalPages);
    setItemsCount(res.data.totalItems);
    if (paginaActual > res.data.totalPages) { setPaginaActual(1) }
    if (action === "delete") {
      setKey(key + 1);
      setMsjToast("Punto de Toma eliminado con exito.");
      setSuccess(true);
    }
  };

  const fetchPuntosTomasByEquipoPlanta = async (id, idPlanta, action) => {
    const res = await getPuntosTomasByEquipoPlanta(paginaActual, orderBy, searchTerm, id, idPlanta);
    let filtroSeleccionado = ""
    if(selectedOptionpla !== null && selectedOptionpla !== '') {
      filtroSeleccionado = {planta:selectedOptionpla, equipo:selectedOptionequi}
    }
    setPuntosTomas(
      res.data.items.map((res) => {
        return {
          equipoId: res.equipo_id.id,
          lubricanteId: res.lubricante_id.id,
          id: res.id,
          codigo: res.codigo_pto,
          denominacion: res.desc_pto,
          lubricante: res.lubricante_id.description,
          componente: res.componente_id.description,
          componenteId: res.componente_id.id,
          equipo: res.equipo_id.description,
          tagExterno: res.codigo_externo_pto,
          clienteId: res.equipo_id.planta.cliente.id,
          cliente: res.equipo_id.planta.cliente.description,
          plantaId: res.equipo_id.planta.id,
          planta: res.equipo_id.planta.description,
          filtro: filtroSeleccionado,
        };
      })

    );
    setPageCount(res.data.totalPages);
    setItemsCount(res.data.totalItems);
    if (paginaActual > res.data.totalPages) { setPaginaActual(1) }
    if (action === "delete") {
      setKey(key + 1);
      setMsjToast("Punto de Toma eliminado con exito.");
      setSuccess(true);
    }
  };

  const fetchEquipos = async () => {
    const res = await getEquiposOptions();
    setEquipo(
      res.data.map((res) => {
        return { label: res.desc_equi, value: res.id };
      })
    );
  };

  const fetchPlantas = async () => {
    const res = await getPlantaOptions();
    setPlantas(
      res.data.map((res) => {
        return { label: res.desc_pla, value: res.id };
      })
    );
  };

  const fetchComponente = async () => {
    const res = await getComponente();
    setComponente(
      res.data.items.map((res) => {
        return { label: res.desc_comp, value: res.id };
      })
    );
  };

  const fetchLubricantes = async () => {
    const res = await getLubricantes();
    setLubricantes(
      res.data.items.map((res) => {
        return { label: res.desc_lub, value: res.id };
      })
    );
  };

  const fetchPuntosTomasByPlanta = async (id, action) => {
    getPuntosTomasByPlanta(paginaActual, orderBy, searchTerm, id).then((res) => {
      let filtroSeleccionado = ""
      if(selectedOptionpla !== null && selectedOptionpla !== '') {
        filtroSeleccionado = {planta:selectedOptionpla, equipo:selectedOptionequi}
      }
      setPuntosTomas(
        res.data.items.map((res) => {
          return {
            equipoId: res.equipo_id.id,
            lubricanteId: res.lubricante_id.id,
            id: res.id,
            codigo: res.codigo_pto,
            denominacion: res.desc_pto,
            lubricante: res.lubricante_id.description,
            componente: res.componente_id.description,
            componenteId: res.componente_id.id,
            equipo: res.equipo_id.description,
            tagExterno: res.codigo_externo_pto,
            clienteId: res.equipo_id.planta.cliente.id,
            cliente: res.equipo_id.planta.cliente.description,
            plantaId: res.equipo_id.planta.id,
            planta: res.equipo_id.planta.description,
            filtro: filtroSeleccionado,
          };
        })
      );
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      if (paginaActual > res.data.totalPages) { setPaginaActual(1) }
      if (action === "delete") {
        setKey(key + 1);
        setMsjToast("Punto de Toma eliminado con exito.");
        setSuccess(true);
      }
    });
  };

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setPuntosTomasId(valueRow.id);
      setModalStatus2(true);
    }
  };

  const handleDelete = (id) => {
    deletePuntoTomas(id)
      .then(() => {
        setPuntosTomasId("");
        let paginaActualTemp = paginaActual;
        if (puntosTomas.length - 1 === 0 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        if (selectedOptionpla !== "" && selectedOptionpla !== null) {
          if (selectedOptionequi !== "" && selectedOptionequi !== null) {
            return fetchPuntosTomasByEquipo(selectedOptionequi.value, "delete")
          } else {
            return fetchPuntosTomasByPlanta(selectedOptionpla.value, "delete")
          }
        } else {
          return fetchPuntosTomasPaginated(
            paginaActualTemp,
            "denominacion",
            searchTerm,
            "delete"
          );
        }
      })
      .catch((err) => {
        // setKey(key + 1);
        setMsjToast("Error al eliminar el Punto de Toma.");
        setError(true);
      });
  };
  const handleClickButtonHeader = () => {

    if(selectedOptionequi !== null || selectedOptionequi !== '' || selectedOptionpla !== null || selectedOptionpla !== '') {
      setDatosModal({selectedOptionequi, selectedOptionpla} );
    }else{
      setDatosModal("");
    }
    setModalStatus(true);
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  return (
    <Styled key={key}>
      <div className="toast">
        {success ? (
          <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            key={key}
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        ) : null}
      </div>

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={({ msj, status, code }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            // setKey(key + 1);
            setSuccess(true);
            setMsjToast(msj);
            if (selectedOptionpla !== "" && selectedOptionpla !== null) {
              if (selectedOptionequi !== "" && selectedOptionequi !== null) {
                fetchPuntosTomasByEquipo(selectedOptionequi.value)
              } else { fetchPuntosTomasByPlanta(selectedOptionpla.value) }
            } else {
              fetchPuntosTomasPaginated(
                paginaActual,
                orderBy,
                searchTerm,
                msj.includes("cread") ? "new" : "",
                code
              );
            }

          } else if (status === "error") {
            // setKey(key + 1);
            setError(true);
            setMsjToast(msj);
          }
          setModalStatus(false);
        }}
        type={"puntosDeToma"}
      />

      <div className="compexterno">
        <HeaderTable
          styledHead="clientes"
          clientes
          title="Puntos de Toma"
          datosButtons={buttonsHeadTable}
          valueBuscador={(value) => setSearchTerm(value)}
          onClickButton={() => {
            handleClickButtonHeader();
          }}
          multiInputs
          selectInput={(e) => {
            setSearchTerm(e.target.value.label);
          }}
          buttonSet={() => {
            setSearchTerm("");
          }}
          clean
          search
          value={searchTerm}
          isClearable={() => { setSearchTerm("") }}
        // buttonSet={()=>{}}
        />

        <div className="compinterno">
          <Input
            isClearable
            reactSelect
            stylesHeader
            placeholder={"Seleccione Planta"}
            options={plantas}
            onChange={(e) => {
              if (e === null) {
                setSelectedOptionpla("");
                //setSelectedOptionequi("");
              } else {
                setSelectedOptionpla(e);
                setSelectedOptionequi("");
                //fetchPuntosTomasByPlanta(e.value);
                // setSearchTerm(e.label);//alert(JSON.stringify(e))
              }
            }}
            value={selectedOptionpla}
          />
          <Input
            isClearable
            reactSelect
            placeholder={"Seleccione Equipo"}
            options={equipo}
            stylesHeader
            onChange={(e) => {
              if (e === null) {
                setSelectedOptionequi("");
                //setSelectedOptionequi("");
              } else {
                setSelectedOptionequi(e);
                //fetchPuntosTomasByPlanta(e.value);
                // setSearchTerm(e.label);//alert(JSON.stringify(e))
              }
            }}
            value={selectedOptionequi}
          />
        </div>
      </div>

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={puntosTomas}
        cRows={20}
        totalRows={itemsCount}
        actualpage={paginaActual}
        pagination={true}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      // onClickSwitch={(id)=> alert(id)}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDelete(puntosTomasId);
        }}
      />
    </Styled>
  );
};

export default PuntosDeToma;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
    //height: 100vh;
  }
  & .compexterno {
    position: relative;
  }
  & .compinterno {
    width: 400px;
    position: absolute;
    left: 29%;
    top: 55%;
    display: flex;
    flex-direction: row;

    gap: 10px;

    & .divInput {
      width: 100%;
    }
  }
`;

import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import styled from "styled-components";
import {
  deleteTipoLubricante,
  getTipoLubricantesSearch,
  getTiposLubricantesPaginated,
} from "../../../../api/Configuracion";
import { CircularProgress } from "@mui/material";
import Modal2 from "../../../../components/Modal/Modal";
import Toast from "../../../../components/Toast/Toast";
const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
];

const TipoUnidades = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [tipoLubricantes, setTipoLubricantes] = useState([]);
  const [tipoLubricanteId, setTipoLubricantesId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getTiposLubricantesPaginated(paginaActual, orderBy, searchTerm).then(
      (res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        // alert(JSON.stringify(res.data))
        setTipoLubricantes(
          res.data.items.map((res) => {
            return {
              id: res.id,
              codigo: res.codigo_tplub,
              descripcion: res.desc_tplub,
              habilitado: res.status,
            };
          })
        );
      }
    );
  }, []);

  useEffect(() => {
    getTiposLubricantesPaginated(paginaActual, orderBy, searchTerm).then(
      (res) => {
        // alert(JSON.stringify(res.data))
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setTipoLubricantes(
          res.data.items.map((res) => {
            return {
              id: res.id,
              codigo: res.codigo_tplub,
              descripcion: res.desc_tplub,
              habilitado: res.status,
            };
          })
        );
      }
    );
  }, [orderBy, paginaActual]);

  useEffect(() => {
    if (searchTerm === "") {
      getTiposLubricantesPaginated(paginaActual, orderBy, searchTerm).then(
        (res) => {
          // alert(JSON.stringify(res.data))
          setPageCount(res.data.totalPages);
          setItemsCount(res.data.totalItems);
          setTipoLubricantes(
            res.data.items.map((res) => {
              return {
                id: res.id,
                codigo: res.codigo_tplub,
                descripcion: res.desc_tplub,
                habilitado: res.status,
              };
            })
          );
        }
      ).catch((err) =>
        console.error("Error al obtener datos de tipo lubricantes:", err)
      );
    } else {
      setPaginaActual(1);
      getTipoLubricantesSearch("", searchTerm).then(
        (res) => {
          // alert(JSON.stringify(res.data))
          setPageCount(res.data.totalPages);
          setItemsCount(res.data.totalItems);
          setTipoLubricantes(
            res.data.items.map((res) => {
              return {
                id: res.id,
                codigo: res.codigo_tplub,
                descripcion: res.desc_tplub,
                habilitado: res.status,
              };
            })
          );
        }
      ).catch((err) =>
        console.error("Error al obtener datos de lubricantes:", err)
      );;
    }
  }, [searchTerm]);


  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setTipoLubricantesId(valueRow.id);
      setModalStatus2(true);
      // handleDeleteTipoLubricante(valueRow.id);
    }
  };
  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };
  const handleDeleteTipoLubricante = (unidadId) => {
    setIsLoading(true);
    setSuccess(false);
    deleteTipoLubricante(unidadId)
      .then(() => {
        setIsLoading(false);
        setTipoLubricantesId("");
        // setKey(key+1);
        setMsjToast("Tipo de de Lubricante eliminado con exito");
        let paginaActualTemp = paginaActual;
        if ((tipoLubricantes.length - 1) === 0 && paginaActual > 1) {
          paginaActualTemp = (paginaActual - 1);
          setPaginaActual(paginaActualTemp);
        }
        return getTiposLubricantesPaginated(paginaActualTemp, orderBy, searchTerm)
      })
      .then((res) => {
        setIsLoading(false);
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setSuccess(true);
        setMsjToast("Tipo de Lubricante eliminado con exito");
        setTimeout(() => {
          setSuccess(false);
          setTipoLubricantes(
            res.data.items.map((res) => {
              return {
                id: res.id,
                codigo: res.codigo_tplub,
                descripcion: res.desc_tplub,
                habilitado: res.status,
              };
            })
          );
        }, 1500);
      })
      .catch((err) => {
        setIsLoading(false);
        setMsjToast("Error al eliminar El tipo de Lubricante")
        setError(true)
      });
  };


  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {

      valor = orderBy
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  return (
    <Styled >
      {isLoading &&
        <CircularProgress size={30}
          sx={{
            position: "fixed",
            bottom: "0",
            padding: "10px",
            zIndex: "9999"
          }}
        />
      }
      {success && (
        <Toast title={msjToast} close={() => setSuccess(false)} />
      )}
      {error && (
        <Toast
          type={"error"}
          title={msjToast}
          close={() => setError(false)}
        />
      )}
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
           
            setSuccess(true);
            setMsjToast(msj);
          } else if (status === "error") {
            
            setError(true);
            setMsjToast(msj);
          }
          setModalStatus(false);

          getTiposLubricantesPaginated(paginaActual, orderBy, searchTerm).then((res) => {
            setPageCount(res.data.totalPages);
            setItemsCount(res.data.totalItems);
            setTipoLubricantes(
              res.data.items.map((res) => {
                return {
                  id: res.id,
                  codigo: res.codigo_tplub,
                  descripcion: res.desc_tplub,
                  habilitado: res.status,
                };
              })
            );
          });
          setTimeout(() => {
            setSuccess(false);
            setError(false); 
            setMsjToast(''); 
          }, 1000)
        }}
        type={"tipoLubricantes"}
      />
      <HeaderTable
        styledHead="cfg"
        cfg
        title="Tipo de Lubricantes"
        valueBuscador={(value) => setSearchTerm(value)}
        labelButton={"Nuevo tipo de lubricante"}
        onClickButton={() => {
          handleClickButtonHeader();
        }}
        value={searchTerm}
        search
        isClearable={() => { setSearchTerm("") }}
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={tipoLubricantes}
        cRows={20}
        totalRows={itemsCount}
        pagination={true}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        actualpage={paginaActual}
        // valueBuscador={valueBuscador}
        onClickSwitch={(value) => { handleOrder(value) }}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteTipoLubricante(tipoLubricanteId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default TipoUnidades;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
   // height: 100vh;
  }
`;
